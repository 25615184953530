<section id="work-process" class="work-process-container">
  <div class="intro">
    <figure><img src="assets/svgs/tilted-logo.svg" alt="" /></figure>
    <h2>{{ "header.workProcess" | translate }}</h2>
  </div>
  <div class="cards">
    @for (card of cards; track $index) {

    <div class="card">
      <div class="header">
        <div>
          <i><img [src]="card.icon" [alt]="card.title" /></i>
        </div>
        <div>
          <h4>{{ card.title }}</h4>
        </div>
      </div>

      <ul>
        @for (step of card.steps; track $index) {
        <li>{{ step }}</li>
        }
      </ul>
    </div>

    }
  </div>
  <figure class="work-process-top-right">
    <img src="assets/svgs/work-process-top-right.svg" alt="" />
  </figure>
  <figure class="work-process-top-left">
    <img src="assets/svgs/work-process-top-left.svg" alt="" />
  </figure>
  <figure class="work-process-bottom-left">
    <img src="assets/svgs/work-process-bottom-left.svg" alt="" />
  </figure>
  <figure class="work-process-bottom-right">
    <img src="assets/svgs/work-process-bottom-right.svg" alt="" />
  </figure>
</section>
