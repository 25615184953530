import { NgFor } from '@angular/common';
import { Component } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-clients',
  standalone: true,
  imports: [TranslateModule, NgFor],
  templateUrl: './clients.component.html',
  styleUrl: './clients.component.scss',
})
export class ClientsComponent {
  logos = [
    {
      img: 'assets/svgs/edu-minty.svg',
      link: '',
    },
    {
      img: 'assets/svgs/client-eve.svg',
      link: '',
    },
    {
      img: 'assets/svgs/client-قطعكم.svg',
      link: '',
    },
    {
      img: 'assets/svgs/client-طبيبك.svg',
      link: '',
    },
    {
      img: 'assets/svgs/Frame.svg',
      link: '',
    },
  ];
}
