<footer class="footer-container">
  <section class="intro">
    <div class="code-mint">
      <div>
        <figure>
          <img
            src="
          assets/svgs/footer-logo.svg
        "
            alt="Code Mint Logo"
          />
        </figure>
      </div>
      <div>
        <p>Fresh Ideas, Minty Solutions!</p>
      </div>
    </div>
    <div class="important-links-container">
      <h2>Important Links</h2>

      <nav class="links">
        <ul>
          <li>
            <a
              class="link"
              href="#services"
              routerLinkActive="active"
              ariaCurrentWhenActive="page"
              >{{ "header.services" | translate }}</a
            >
          </li>
          <li>
            <a
              class="link"
              href="#products"
              routerLinkActive="active"
              ariaCurrentWhenActive="page"
              >{{ "header.products" | translate }}</a
            >
          </li>
        </ul>
        <ul>
          <li>
            <a
              class="link"
              href="#work-process"
              routerLinkActive="active"
              ariaCurrentWhenActive="page"
              >{{ "header.workProcess" | translate }}</a
            >
          </li>
          <li>
            <a
              class="link"
              href="#contact-us"
              routerLinkActive="active"
              ariaCurrentWhenActive="page"
              >{{ "header.contactUs" | translate }}</a
            >
          </li>
        </ul>
      </nav>
    </div>
    <div></div>
  </section>
  <div>
    <hr id="divider" />
  </div>

  <section class="copy-rights-and-social">
    <div class="copy-rights">
      Copyright &copy; 2023-{{ this.currentYear }}. All rights reserved to
      Codemint
    </div>
    <div></div>

    <div class="social">
      <a
        target="_blank"
        href="https://www.facebook.com/profile.php?id=61555636679903"
        ><i><img src="assets/svgs/facebook.svg" width="30" /></i>
      </a>
      <a target="_blank" href="https://www.instagram.com/code.mint/"
        ><i><img src="assets/svgs/instagram.svg" width="30" /></i>
      </a>
      <a target="_blank" href=""
        ><i><img src="assets/svgs/x.svg" width="30" /></i>
      </a>
      <a target="_blank" href="https://www.linkedin.com/company/code-mint/"
        ><i><img src="assets/svgs/linked-in.svg" width="30" /></i
      ></a>
    </div>
  </section>

  <figure class="login-top-right-shape">
    <img src="assets/svgs/footer-logos.svg" alt="" />
  </figure>
</footer>
