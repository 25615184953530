<section id="intro" class="intro-container">
  <div class="slogan">
    <h2>Fresh <b>Ideas</b></h2>
    <h2>Minty Solutions!</h2>
    <p>
      Empower your digital journey with our premier software development
      solutions.
    </p>
  </div>
  <div class="intro-ill">
    <figure><img src="assets/svgs/intro-ill.svg" alt="" /></figure>
  </div>
  <!-- <figure class="intro-background">
    <img src="assets/svgs/intro-background.svg" alt="" />
  </figure> -->
</section>
