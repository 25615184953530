<header
  class="header-container"
  [style.background]="isMobileMenuOpen ? '#478c0d' : 'white'"
>
  <div class="code-mint-logo">
    <figure>
      <img
        [src]="
          'assets/svgs/' +
          (isMobileMenuOpen ? 'white' : 'normal') +
          '-full-logo.svg'
        "
        alt="Code Mint Logo"
      />
    </figure>
  </div>
  <nav class="links">
    <ul aria-label="Primary" role="list" class="nav-list">
      <li>
        <a
          class="link"
          href="#services"
          routerLinkActive="active"
          ariaCurrentWhenActive="page"
          >{{ "header.services" | translate }}</a
        >
      </li>
      <li>
        <a
          class="link"
          href="#work-process"
          routerLinkActive="active"
          ariaCurrentWhenActive="page"
          >{{ "header.workProcess" | translate }}</a
        >
      </li>
      <li>
        <a
          class="link"
          href="#products"
          routerLinkActive="active"
          ariaCurrentWhenActive="page"
          >{{ "header.products" | translate }}</a
        >
      </li>
      <li>
        <a
          class="link"
          href="#contact-us"
          routerLinkActive="active"
          ariaCurrentWhenActive="page"
          >{{ "header.contactUs" | translate }}</a
        >
      </li>
    </ul>
  </nav>
  <div class="language" (click)="this.changeLanguage()">
    <!-- <figure>
      <img src="assets/svgs/language.svg" alt="Change Language" />
    </figure> -->
  </div>
  <div class="mobile-menu-toggle">
    <figure>
      <img
        [src]="'assets/svgs/' + (isMobileMenuOpen ? 'close' : 'menu') + '.svg'"
        alt="mobile menu toggle"
        (click)="this.toggleMobileMenu()"
      />
    </figure>
  </div>
  <nav
    class="mobile-menu"
    [style.display]="isMobileMenuOpen ? 'block' : 'none'"
  >
    <ul aria-label="Primary" role="list" class="nav-list">
      <li>
        <a
          class="link"
          href="#services"
          routerLinkActive="active"
          ariaCurrentWhenActive="page"
          >{{ "header.services" | translate }}</a
        >
        <hr />
      </li>
      <li>
        <a
          class="link"
          href="#work-process"
          routerLinkActive="active"
          ariaCurrentWhenActive="page"
          >{{ "header.workProcess" | translate }}</a
        >
        <hr />
      </li>
      <li>
        <a
          class="link"
          href="#products"
          routerLinkActive="active"
          ariaCurrentWhenActive="page"
          >{{ "header.products" | translate }}</a
        >
        <hr />
      </li>
      <li>
        <a
          class="link"
          href="#contact-us"
          routerLinkActive="active"
          ariaCurrentWhenActive="page"
          >{{ "header.contactUs" | translate }}</a
        >
        <hr />
      </li>
      <li class="mobile-language" (click)="this.changeLanguage()">
        <!-- <figure>
          <img src="assets/svgs/white-language.svg" alt="Change Language" />
        </figure> -->
      </li>
    </ul>
  </nav>
</header>
