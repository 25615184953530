import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appPreventSpace]',
  standalone: true,
})
export class PreventSpaceDirective {
  constructor(private el: ElementRef<HTMLInputElement>) {}
  @HostListener('keydown') onKeyDown() {
    this.el.nativeElement.value = this.el.nativeElement.value.trim();
  }
}
