<div class="phone-number-input-container">
  <div class="countries" (click)="this.openCountriesDropDown()">
    <figure><img [src]="selectedItem.flag" alt="" /></figure>
    <h5>{{ selectedItem.dialCode }}</h5>
    <figure><img src="assets/svgs/top-down.svg" alt="" /></figure>
  </div>
  <div class="input">
    <input
      appPreventSpace
      appNumbersOnly
      inputmode="numeric"
      [formControl]="this.phoneNumberControl"
      type="text"
    />
  </div>
</div>

<div
  class="countries-drop-down"
  [style.display]="this.isDropDownOpen ? 'flex' : 'none'"
>
  <div class="country-container">
    <input
      type="text"
      placeholder="Country Name"
      [(ngModel)]="searchText"
      (input)="this.search($event)"
    />

    @for (country of this.COUNTRIES; track $index) {
    <div class="country" (click)="this.selectItem(country)">
      <figure><img [src]="country.flag" alt="" /></figure>
      <p>{{ country.dialCode }}</p>
      <p>{{ country["common.translatableName" | translate] }}</p>
    </div>

    }
  </div>
</div>
