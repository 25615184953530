import { Country } from '../interfaces/country.interface';

export const COUNTRIES: Country[] = [
  {
    dialCode: '+93',
    isoCode: 'AF',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/af.svg',
    nameEn: 'Afghanistan',
    nameAr: 'أفغانستان',
    emoji: '🇦🇫',
    secondFlag:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AF.svg',
    region: 'Asia',
    timezones: {
      'Asia/Kabul': '+04:30',
    },
    phoneLength: 9,
  },
  {
    dialCode: '+355',
    isoCode: 'AL',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/al.svg',
    nameEn: 'Albania',
    nameAr: 'ألبانيا',
    emoji: '🇦🇱',
    secondFlag:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AL.svg',
    region: 'Europe',
    timezones: {
      'Europe/Tirane': '+02:00',
    },
    phoneLength: 9,
  },
  {
    dialCode: '+213',
    isoCode: 'DZ',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/dz.svg',
    nameEn: 'Algeria',
    nameAr: 'الجزائر',
    emoji: '🇩🇿',
    secondFlag:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/DZ.svg',
    region: 'Africa',
    timezones: {
      'Africa/Algiers': '+01:00',
    },
    phoneLength: 9,
  },
  {
    dialCode: '+1684',
    isoCode: 'AS',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/as.svg',
    nameEn: 'American Samoa',
    nameAr: 'ساموا الأمريكية',
    emoji: '🇦🇸',
    secondFlag:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AS.svg',
    region: 'Oceania',
    timezones: {
      'Pacific/Pago_Pago': '-11:00',
    },
    phoneLength: 10,
  },
  {
    dialCode: '+376',
    isoCode: 'AD',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/ad.svg',
    nameEn: 'Andorra',
    nameAr: 'أندورا',
    emoji: '🇦🇩',
    secondFlag:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AD.svg',
    region: 'Europe',
    timezones: {
      'Europe/Andorra': '+02:00',
    },
    phoneLength: 6,
  },
  {
    dialCode: '+244',
    isoCode: 'AO',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/ao.svg',
    nameEn: 'Angola',
    nameAr: 'أنغولا',
    emoji: '🇦🇴',
    secondFlag:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AO.svg',
    region: 'Africa',
    timezones: {
      'Africa/Luanda': '+01:00',
    },
    phoneLength: 9,
  },
  {
    dialCode: '+1264',
    isoCode: 'AI',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/ai.svg',
    nameEn: 'Anguilla',
    nameAr: 'أنغيلا',
    emoji: '🇦🇮',
    secondFlag:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AI.svg',
    region: 'Americas',
    timezones: {
      'America/Anguilla': '-04:00',
    },
    phoneLength: 10,
  },
  {
    dialCode: '+672',
    isoCode: 'AQ',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/aq.svg',
    nameEn: 'Antarctica',
    nameAr: 'أنتاركتيكا',
    emoji: '🇦🇶',
    secondFlag:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AQ.svg',
    region: 'continent',
    timezones: {
      'Antarctica/Casey': '+11:00',
      'Antarctica/Davis': '+07:00',
      'Antarctica/DumontDUrville': '+10:00',
      'Antarctica/Mawson': '+05:00',
      'Antarctica/McMurdo': '+12:00',
      'Antarctica/Palmer': '-03:00',
      'Antarctica/Rothera': '-03:00',
      'Antarctica/Syowa': '+03:00',
      'Antarctica/Troll': '+02:00',
      'Antarctica/Vostok': '+06:00',
    },
    phoneLength: 6,
  },
  {
    dialCode: '+1268',
    isoCode: 'AG',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/ag.svg',
    nameEn: 'Antigua and Barbuda',
    nameAr: 'أنتيغوا وبربودا',
    emoji: '🇦🇬',
    secondFlag:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AG.svg',
    region: 'Americas',
    timezones: {
      'America/Antigua': '-04:00',
    },
    phoneLength: 10,
  },
  {
    dialCode: '+54',
    isoCode: 'AR',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/ar.svg',
    nameEn: 'Argentina',
    nameAr: 'الأرجنتين',
    emoji: '🇦🇷',
    secondFlag:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AR.svg',
    region: 'Americas',
    timezones: {
      'America/Argentina/Buenos_Aires': '-03:00',
      'America/Argentina/Catamarca': '-03:00',
      'America/Argentina/Cordoba': '-03:00',
      'America/Argentina/Jujuy': '-03:00',
      'America/Argentina/La_Rioja': '-03:00',
      'America/Argentina/Mendoza': '-03:00',
      'America/Argentina/Rio_Gallegos': '-03:00',
      'America/Argentina/Salta': '-03:00',
      'America/Argentina/San_Juan': '-03:00',
      'America/Argentina/San_Luis': '-03:00',
      'America/Argentina/Tucuman': '-03:00',
      'America/Argentina/Ushuaia': '-03:00',
    },
    phoneLength: 8,
  },
  {
    dialCode: '+374',
    isoCode: 'AM',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/am.svg',
    nameEn: 'Armenia',
    nameAr: 'أرمينيا',
    emoji: '🇦🇲',
    secondFlag:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AM.svg',
    region: 'Asia',
    timezones: {
      'Asia/Yerevan': '+04:00',
    },
    phoneLength: 6,
  },
  {
    dialCode: '+297',
    isoCode: 'AW',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/aw.svg',
    nameEn: 'Aruba',
    nameAr: 'أروبا',
    emoji: '🇦🇼',
    secondFlag:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AW.svg',
    region: 'Americas',
    timezones: {
      'America/Aruba': '-04:00',
    },
    phoneLength: 7,
  },
  {
    dialCode: '+61',
    isoCode: 'AU',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/au.svg',
    nameEn: 'Australia',
    nameAr: 'أستراليا',
    emoji: '🇦🇺',
    secondFlag:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AU.svg',
    region: 'Oceania',
    timezones: {
      'Antarctica/Macquarie': '+10:00',
      'Australia/Adelaide': '+09:30',
      'Australia/Brisbane': '+10:00',
      'Australia/Broken_Hill': '+09:30',
      'Australia/Darwin': '+09:30',
      'Australia/Eucla': '+08:45',
      'Australia/Hobart': '+10:00',
      'Australia/Lindeman': '+10:00',
      'Australia/Lord_Howe': '+10:30',
      'Australia/Melbourne': '+10:00',
      'Australia/Perth': '+08:00',
      'Australia/Sydney': '+10:00',
    },
    phoneLength: 9,
  },
  {
    dialCode: '+43',
    isoCode: 'AT',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/at.svg',
    nameEn: 'Austria',
    nameAr: 'النمسا',
    emoji: '🇦🇹',
    secondFlag:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AT.svg',
    region: 'Europe',
    timezones: {
      'Europe/Vienna': '+02:00',
    },
    phoneLength: 11,
  },
  {
    dialCode: '+994',
    isoCode: 'AZ',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/az.svg',
    nameEn: 'Azerbaijan',
    nameAr: 'أذربيجان',
    emoji: '🇦🇿',
    secondFlag:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AZ.svg',
    region: 'Asia',
    timezones: {
      'Asia/Baku': '+04:00',
    },
    phoneLength: 9,
  },
  {
    dialCode: '+1242',
    isoCode: 'BS',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/bs.svg',
    nameEn: 'Bahamas',
    nameAr: 'جزر البهاما',
    emoji: '🇧🇸',
    secondFlag:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BS.svg',
    region: 'Americas',
    timezones: {
      'America/Nassau': '-04:00',
    },
    phoneLength: 10,
  },
  {
    dialCode: '+973',
    isoCode: 'BH',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/bh.svg',
    nameEn: 'Bahrain',
    nameAr: 'البحرين',
    emoji: '🇧🇭',
    secondFlag:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BH.svg',
    region: 'Asia',
    timezones: {
      'Asia/Bahrain': '+03:00',
    },
    phoneLength: 8,
  },
  {
    dialCode: '+880',
    isoCode: 'BD',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/bd.svg',
    nameEn: 'Bangladesh',
    nameAr: 'بنغلاديش',
    emoji: '🇧🇩',
    secondFlag:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BD.svg',
    region: 'Asia',
    timezones: {
      'Asia/Dhaka': '+06:00',
    },
    phoneLength: 10,
  },
  {
    dialCode: '+1246',
    isoCode: 'BB',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/bb.svg',
    nameEn: 'Barbados',
    nameAr: 'بربادوس',
    emoji: '🇧🇧',
    secondFlag:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BB.svg',
    region: 'Americas',
    timezones: {
      'America/Barbados': '-04:00',
    },
    phoneLength: 10,
  },
  {
    dialCode: '+375',
    isoCode: 'BY',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/by.svg',
    nameEn: 'Belarus',
    nameAr: 'بيلاروسيا',
    emoji: '🇧🇾',
    secondFlag:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BY.svg',
    region: 'Europe',
    timezones: {
      'Europe/Minsk': '+03:00',
    },
    phoneLength: 9,
  },
  {
    dialCode: '+32',
    isoCode: 'BE',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/be.svg',
    nameEn: 'Belgium',
    nameAr: 'بلجيكا',
    emoji: '🇧🇪',
    secondFlag:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BE.svg',
    region: 'Europe',
    timezones: {
      'Europe/Brussels': '+02:00',
    },
    phoneLength: 9,
  },
  {
    dialCode: '+501',
    isoCode: 'BZ',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/bz.svg',
    nameEn: 'Belize',
    nameAr: 'بليز',
    emoji: '🇧🇿',
    secondFlag:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BZ.svg',
    region: 'Americas',
    timezones: {
      'America/Belize': '-06:00',
    },
    phoneLength: 7,
  },
  {
    dialCode: '+229',
    isoCode: 'BJ',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/bj.svg',
    nameEn: 'Benin',
    nameAr: 'بنين',
    emoji: '🇧🇯',
    secondFlag:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BJ.svg',
    region: 'Africa',
    timezones: {
      'Africa/Porto-Novo': '+01:00',
    },
    phoneLength: 8,
  },
  {
    dialCode: '+1441',
    isoCode: 'BM',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/bm.svg',
    nameEn: 'Bermuda',
    nameAr: 'برمودا',
    emoji: '🇧🇲',
    secondFlag:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BM.svg',
    region: 'Americas',
    timezones: {
      'Atlantic/Bermuda': '-03:00',
    },
    phoneLength: 10,
  },
  {
    dialCode: '+975',
    isoCode: 'BT',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/bt.svg',
    nameEn: 'Bhutan',
    nameAr: 'بوتان',
    emoji: '🇧🇹',
    secondFlag:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BT.svg',
    region: 'Asia',
    timezones: {
      'Asia/Thimphu': '+06:00',
    },
    phoneLength: 7,
  },
  {
    dialCode: '+591',
    isoCode: 'BO',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/bo.svg',
    nameEn: 'Bolivia',
    nameAr: 'بوليفيا',
    emoji: '🇧🇴',
    secondFlag:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BO.svg',
    region: 'Americas',
    timezones: {
      'America/La_Paz': '-04:00',
    },
    phoneLength: 9,
  },
  {
    dialCode: '+387',
    isoCode: 'BA',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/ba.svg',
    nameEn: 'Bosnia and Herzegovina',
    nameAr: 'البوسنة والهرسك',
    emoji: '🇧🇦',
    secondFlag:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BA.svg',
    region: 'Europe',
    timezones: {
      'Europe/Sarajevo': '+02:00',
    },
    phoneLength: 8,
  },
  {
    dialCode: '+267',
    isoCode: 'BW',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/bw.svg',
    nameEn: 'Botswana',
    nameAr: 'بوتسوانا',
    emoji: '🇧🇼',
    secondFlag:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BW.svg',
    region: 'Africa',
    timezones: {
      'Africa/Gaborone': '+02:00',
    },
    phoneLength: 7,
  },
  {
    dialCode: '+55',
    isoCode: 'BR',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/br.svg',
    nameEn: 'Brazil',
    nameAr: ' البرازيل ',
    emoji: '🇧🇷',
    secondFlag:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BR.svg',
    region: 'Americas',
    timezones: {
      'America/Araguaina': '-03:00',
      'America/Bahia': '-03:00',
      'America/Belem': '-03:00',
      'America/Boa_Vista': '-04:00',
      'America/Campo_Grande': '-04:00',
      'America/Cuiaba': '-04:00',
      'America/Eirunepe': '-05:00',
      'America/Fortaleza': '-03:00',
      'America/Maceio': '-03:00',
      'America/Manaus': '-04:00',
      'America/Noronha': '-02:00',
      'America/Porto_Velho': '-04:00',
      'America/Recife': '-03:00',
      'America/Rio_Branco': '-05:00',
      'America/Santarem': '-03:00',
      'America/Sao_Paulo': '-03:00',
    },
    phoneLength: 11,
  },
  {
    dialCode: '+246',
    isoCode: 'IO',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/io.svg',
    nameEn: 'British Indian Ocean Territory',
    nameAr: 'إقليم المحيط الهندي البريطاني',
    emoji: '🇮🇴',
    secondFlag:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/IO.svg',
    region: 'Africa',
    timezones: {
      'Indian/Chagos': '+06:00',
    },
    phoneLength: 7,
  },
  {
    dialCode: '+673',
    isoCode: 'BN',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/bn.svg',
    nameEn: 'Brunei Darussalam',
    nameAr: 'بروناي دار السلام',
    emoji: '🇧🇳',
    secondFlag:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BN.svg',
    region: 'Asia',
    timezones: {
      'Asia/Brunei': '+08:00',
    },
    phoneLength: 7,
  },
  {
    dialCode: '+359',
    isoCode: 'BG',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/bg.svg',
    nameEn: 'Bulgaria',
    nameAr: 'بلغاريا',
    emoji: '🇧🇬',
    secondFlag:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BG.svg',
    region: 'Europe',
    timezones: {
      'Europe/Sofia': '+03:00',
    },
    phoneLength: 9,
  },
  {
    dialCode: '+226',
    isoCode: 'BF',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/bf.svg',
    nameEn: 'Burkina Faso',
    nameAr: 'بوركينا فاسو',
    emoji: '🇧🇫',
    secondFlag:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BF.svg',
    region: 'Africa',
    timezones: {
      'Africa/Ouagadougou': '+00:00',
    },
    phoneLength: 8,
  },
  {
    dialCode: '+257',
    isoCode: 'BI',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/bi.svg',
    nameEn: 'Burundi',
    nameAr: 'بوروندي',
    emoji: '🇧🇮',
    secondFlag:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BI.svg',
    region: 'Africa',
    timezones: {
      'Africa/Bujumbura': '+02:00',
    },
    phoneLength: 8,
  },
  {
    dialCode: '+855',
    isoCode: 'KH',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/kh.svg',
    nameEn: 'Cambodia',
    nameAr: 'كمبوديا',
    emoji: '🇰🇭',
    secondFlag:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/KH.svg',
    region: 'Asia',
    timezones: {
      'Asia/Phnom_Penh': '+07:00',
    },
    phoneLength: 9,
  },
  {
    dialCode: '+237',
    isoCode: 'CM',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/cm.svg',
    nameEn: 'Cameroon',
    nameAr: 'الكاميرون',
    emoji: '🇨🇲',
    secondFlag:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CM.svg',
    region: 'Africa',
    timezones: {
      'Africa/Douala': '+01:00',
    },
    phoneLength: 9,
  },
  {
    dialCode: '+1',
    isoCode: 'CA',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/ca.svg',
    nameEn: 'Canada',
    nameAr: 'كندا',
    emoji: '🇨🇦',
    secondFlag:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CA.svg',
    region: 'Americas',
    timezones: {
      'America/Atikokan': '-05:00',
      'America/Blanc-Sablon': '-04:00',
      'America/Cambridge_Bay': '-06:00',
      'America/Creston': '-07:00',
      'America/Dawson': '-07:00',
      'America/Dawson_Creek': '-07:00',
      'America/Edmonton': '-06:00',
      'America/Fort_Nelson': '-07:00',
      'America/Glace_Bay': '-03:00',
      'America/Goose_Bay': '-03:00',
      'America/Halifax': '-03:00',
      'America/Inuvik': '-06:00',
      'America/Iqaluit': '-04:00',
      'America/Moncton': '-03:00',
      'America/Rankin_Inlet': '-05:00',
      'America/Regina': '-06:00',
      'America/Resolute': '-05:00',
      'America/St_Johns': '-02:30',
      'America/Swift_Current': '-06:00',
      'America/Toronto': '-04:00',
      'America/Vancouver': '-07:00',
      'America/Whitehorse': '-07:00',
      'America/Winnipeg': '-05:00',
    },
    phoneLength: 10,
  },
  {
    dialCode: '+238',
    isoCode: 'CV',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/cv.svg',
    nameEn: 'Cape Verde',
    nameAr: 'الرأس الأخضر',
    emoji: '🇨🇻',
    secondFlag:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CV.svg',
    region: 'Africa',
    timezones: {
      'Atlantic/Cape_Verde': '-01:00',
    },
    phoneLength: 7,
  },
  {
    dialCode: '+1345',
    isoCode: 'KY',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/ky.svg',
    nameEn: 'Cayman Islands',
    nameAr: 'جزر كايمان',
    emoji: '🇰🇾',
    secondFlag:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/KY.svg',
    region: 'Americas',
    timezones: {
      'America/Cayman': '-05:00',
    },
    phoneLength: 7,
  },
  {
    dialCode: '+236',
    isoCode: 'CF',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/cf.svg',
    nameEn: 'Central African Republic',
    nameAr: 'جمهورية أفريقيا الوسطى',
    emoji: '🇨🇫',
    secondFlag:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CF.svg',
    region: 'Africa',
    timezones: {
      'Africa/Bangui': '+01:00',
    },
    phoneLength: 8,
  },
  {
    dialCode: '+235',
    isoCode: 'TD',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/td.svg',
    nameEn: 'Chad',
    nameAr: 'تشاد',
    emoji: '🇹🇩',
    secondFlag:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TD.svg',
    region: 'Africa',
    timezones: {
      'Africa/Ndjamena': '+01:00',
    },
    phoneLength: 6,
  },
  {
    dialCode: '+56',
    isoCode: 'CL',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/cl.svg',
    nameEn: 'Chile',
    nameAr: 'تشيلي',
    emoji: '🇨🇱',
    secondFlag:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CL.svg',
    region: 'Americas',
    timezones: {
      'America/Punta_Arenas': '-03:00',
      'America/Santiago': '-04:00',
      'Pacific/Easter': '-06:00',
    },
    phoneLength: 9,
  },
  {
    dialCode: '+86',
    isoCode: 'CN',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/cn.svg',
    nameEn: 'China',
    nameAr: 'الصين',
    emoji: '🇨🇳',
    secondFlag:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CN.svg',
    region: 'Asia',
    timezones: {
      'Asia/Shanghai': '+08:00',
      'Asia/Urumqi': '+06:00',
    },
    phoneLength: 11,
  },
  {
    dialCode: '+61',
    isoCode: 'CX',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/cx.svg',
    nameEn: 'Christmas Island',
    nameAr: 'جزيرة الكريسماس',
    emoji: '🇨🇽',
    secondFlag:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CX.svg',
    region: 'Oceania',
    timezones: {
      'Indian/Christmas': '+07:00',
    },
    phoneLength: 9,
  },
  {
    dialCode: '+61',
    isoCode: 'CC',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/cc.svg',
    nameEn: 'Cocos (Keeling) Islands',
    nameAr: 'جزر كوكوس (كيلينغ)',
    emoji: '🇨🇨',
    secondFlag:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CC.svg',
    region: 'Oceania',
    timezones: {
      'Indian/Cocos': '+06:30',
    },
    phoneLength: 10,
  },
  {
    dialCode: '+57',
    isoCode: 'CO',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/co.svg',
    nameEn: 'Colombia',
    nameAr: 'كولومبيا',
    emoji: '🇨🇴',
    secondFlag:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CO.svg',
    region: 'Americas',
    timezones: {
      'America/Bogota': '-05:00',
    },
    phoneLength: 10,
  },
  {
    dialCode: '+269',
    isoCode: 'KM',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/km.svg',
    nameEn: 'Comoros',
    nameAr: 'جزر القمر',
    emoji: '🇰🇲',
    secondFlag:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/KM.svg',
    region: 'Africa',
    timezones: {
      'Indian/Comoro': '+03:00',
    },
    phoneLength: 7,
  },
  {
    dialCode: '+242',
    isoCode: 'CG',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/cg.svg',
    nameEn: 'Congo',
    nameAr: 'الكونغو',
    emoji: '🇨🇬',
    secondFlag:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CG.svg',
    region: 'Africa',
    timezones: {
      'Africa/Brazzaville': '+01:00',
    },
    phoneLength: 9,
  },
  {
    dialCode: '+243',
    isoCode: 'CD',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/cd.svg',
    nameEn: 'Democratic Republic of the Congo',
    nameAr: 'جمهورية الكونغو الديمقراطية',
    emoji: '🇨🇩',
    secondFlag:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CD.svg',
    region: 'Africa',
    timezones: {
      'Africa/Kinshasa': '+01:00',
      'Africa/Lubumbashi': '+02:00',
    },
    phoneLength: 7,
  },
  {
    dialCode: '+682',
    isoCode: 'CK',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/ck.svg',
    nameEn: 'Cook Islands',
    nameAr: 'جزر كوك',
    emoji: '🇨🇰',
    secondFlag:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CK.svg',
    region: 'Oceania',
    timezones: {
      'Pacific/Rarotonga': '-10:00',
    },
    phoneLength: 5,
  },
  {
    dialCode: '+506',
    isoCode: 'CR',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/cr.svg',
    nameEn: 'Costa Rica',
    nameAr: 'كوستاريكا',
    emoji: '🇨🇷',
    secondFlag:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CR.svg',
    region: 'Americas',
    timezones: {
      'America/Costa_Rica': '-06:00',
    },
    phoneLength: 8,
  },
  {
    dialCode: '+385',
    isoCode: 'HR',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/hr.svg',
    nameEn: 'Croatia',
    nameAr: 'كرواتيا',
    emoji: '🇭🇷',
    secondFlag:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/HR.svg',
    region: 'Europe',
    timezones: {
      'Europe/Zagreb': '+02:00',
    },
    phoneLength: 9,
  },
  {
    dialCode: '+53',
    isoCode: 'CU',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/cu.svg',
    nameEn: 'Cuba',
    nameAr: 'كوبا',
    emoji: '🇨🇺',
    secondFlag:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CU.svg',
    region: 'Americas',
    timezones: {
      'America/Havana': '-04:00',
    },
    phoneLength: 8,
  },
  {
    dialCode: '+357',
    isoCode: 'CY',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/cy.svg',
    nameEn: 'Cyprus',
    nameAr: 'قبرص',
    emoji: '🇨🇾',
    secondFlag:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CY.svg',
    region: 'Asia',
    timezones: {
      'Asia/Famagusta': '+03:00',
      'Asia/Nicosia': '+03:00',
    },
    phoneLength: 8,
  },
  {
    dialCode: '+420',
    isoCode: 'CZ',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/cz.svg',
    nameEn: 'Czech Republic',
    nameAr: 'جمهورية التشيك',
    emoji: '🇨🇿',
    secondFlag:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CZ.svg',
    region: 'Europe',
    timezones: {
      'Europe/Prague': '+02:00',
    },
    phoneLength: 9,
  },
  {
    dialCode: '+225',
    isoCode: 'CI',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/ci.svg',
    nameEn: "Ivory Coast / Cote d'Ivoire",
    nameAr: 'ساحل العاج / كوت ديفوار',
    emoji: '🇨🇮',
    secondFlag:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CI.svg',
    region: 'Africa',
    timezones: {
      'Africa/Abidjan': '+00:00',
    },
    phoneLength: 8,
  },
  {
    dialCode: '+45',
    isoCode: 'DK',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/dk.svg',
    nameEn: 'Denmark',
    nameAr: 'الدنمارك',
    emoji: '🇩🇰',
    secondFlag:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/DK.svg',
    region: 'Europe',
    timezones: {
      'Europe/Copenhagen': '+02:00',
    },
    phoneLength: 8,
  },
  {
    dialCode: '+253',
    isoCode: 'DJ',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/dj.svg',
    nameEn: 'Djibouti',
    nameAr: 'جيبوتي',
    emoji: '🇩🇯',
    secondFlag:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/DJ.svg',
    region: 'Africa',
    timezones: {
      'Africa/Djibouti': '+03:00',
    },
    phoneLength: 10,
  },
  {
    dialCode: '+1767',
    isoCode: 'DM',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/dm.svg',
    nameEn: 'Dominica',
    nameAr: 'دومينيكا',
    emoji: '🇩🇲',
    secondFlag:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/DM.svg',
    region: 'Americas',
    timezones: {
      'America/Dominica': '-04:00',
    },
    phoneLength: 10,
  },
  {
    dialCode: '+1849',
    isoCode: 'DO',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/do.svg',
    nameEn: 'Dominican Republic',
    nameAr: 'جمهورية الدومينيكان',
    emoji: '🇩🇴',
    secondFlag:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/DO.svg',
    region: 'Americas',
    timezones: {
      'America/Santo_Domingo': '-04:00',
    },
    phoneLength: 10,
  },
  {
    dialCode: '+593',
    isoCode: 'EC',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/ec.svg',
    nameEn: 'Ecuador',
    nameAr: 'الإكوادور',
    emoji: '🇪🇨',
    secondFlag:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/EC.svg',
    region: 'Americas',
    timezones: {
      'America/Guayaquil': '-05:00',
      'Pacific/Galapagos': '-06:00',
    },
    phoneLength: 9,
  },
  {
    dialCode: '+20',
    isoCode: 'EG',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/eg.svg',
    nameEn: 'Egypt',
    nameAr: 'مصر',
    emoji: '🇪🇬',
    secondFlag:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/EG.svg',
    region: 'Africa',
    timezones: {
      'Africa/Cairo': '+02:00',
    },
    phoneLength: 10,
  },
  {
    dialCode: '+503',
    isoCode: 'SV',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/sv.svg',
    nameEn: 'El Salvador',
    nameAr: 'السلفادور',
    emoji: '🇸🇻',
    secondFlag:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SV.svg',
    region: 'Americas',
    timezones: {
      'America/El_Salvador': '-06:00',
    },
    phoneLength: 8,
  },
  {
    dialCode: '+240',
    isoCode: 'GQ',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/gq.svg',
    nameEn: 'Equatorial Guinea',
    nameAr: 'غينيا الاستوائية',
    emoji: '🇬🇶',
    secondFlag:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GQ.svg',
    region: 'Africa',
    timezones: {
      'Africa/Malabo': '+01:00',
    },
    phoneLength: 9,
  },
  {
    dialCode: '+291',
    isoCode: 'ER',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/er.svg',
    nameEn: 'Eritrea',
    nameAr: 'إريتريا',
    emoji: '🇪🇷',
    secondFlag:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/ER.svg',
    region: 'Africa',
    timezones: {
      'Africa/Asmara': '+03:00',
    },
    phoneLength: 7,
  },
  {
    dialCode: '+372',
    isoCode: 'EE',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/ee.svg',
    nameEn: 'Estonia',
    nameAr: 'إستونيا',
    emoji: '🇪🇪',
    secondFlag:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/EE.svg',
    region: 'Europe',
    timezones: {
      'Europe/Tallinn': '+03:00',
    },
    phoneLength: 8,
  },
  {
    dialCode: '+251',
    isoCode: 'ET',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/et.svg',
    nameEn: 'Ethiopia',
    nameAr: 'إثيوبيا',
    emoji: '🇪🇹',
    secondFlag:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/ET.svg',
    region: 'Africa',
    timezones: {
      'Africa/Addis_Ababa': '+03:00',
    },
    phoneLength: 9,
  },
  {
    dialCode: '+500',
    isoCode: 'FK',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/fk.svg',
    nameEn: 'Falkland Islands (Malvinas)',
    nameAr: 'جزر فوكلاند (مالفيناس)',
    emoji: '🇫🇰',
    secondFlag:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/FK.svg',
    region: 'Americas',
    timezones: {
      'Atlantic/Stanley': '-03:00',
    },
    phoneLength: 5,
  },
  {
    dialCode: '+298',
    isoCode: 'FO',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/fo.svg',
    nameEn: 'Faroe Islands',
    nameAr: 'جزر فارو',
    emoji: '🇫🇴',
    secondFlag:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/FO.svg',
    region: 'Europe',
    timezones: {
      'Atlantic/Faroe': '+01:00',
    },
    phoneLength: 5,
  },
  {
    dialCode: '+679',
    isoCode: 'FJ',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/fj.svg',
    nameEn: 'Fiji',
    nameAr: 'فيجي',
    emoji: '🇫🇯',
    secondFlag:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/FJ.svg',
    region: 'Oceania',
    timezones: {
      'Pacific/Fiji': '+12:00',
    },
    phoneLength: 7,
  },
  {
    dialCode: '+358',
    isoCode: 'FI',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/fi.svg',
    nameEn: 'Finland',
    nameAr: 'فنلندا',
    emoji: '🇫🇮',
    secondFlag:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/FI.svg',
    region: 'Europe',
    timezones: {
      'Europe/Helsinki': '+03:00',
    },
    phoneLength: 7,
  },
  {
    dialCode: '+33',
    isoCode: 'FR',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/fr.svg',
    nameEn: 'France',
    nameAr: 'فرنسا',
    emoji: '🇫🇷',
    secondFlag:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/FR.svg',
    region: 'Europe',
    timezones: {
      'Europe/Paris': '+02:00',
    },
    phoneLength: 9,
  },
  {
    dialCode: '+594',
    isoCode: 'GF',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/gf.svg',
    nameEn: 'French Guiana',
    nameAr: 'غيانا الفرنسية',
    emoji: '🇬🇫',
    secondFlag:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GF.svg',
    region: 'Americas',
    timezones: {
      'America/Cayenne': '-03:00',
    },
    phoneLength: 9,
  },
  {
    dialCode: '+689',
    isoCode: 'PF',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/pf.svg',
    nameEn: 'French Polynesia',
    nameAr: 'بولينيزيا الفرنسية',
    emoji: '🇵🇫',
    secondFlag:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PF.svg',
    region: 'Oceania',
    timezones: {
      'Pacific/Gambier': '-09:00',
      'Pacific/Marquesas': '-09:30',
      'Pacific/Tahiti': '-10:00',
    },
    phoneLength: 8,
  },
  {
    dialCode: '+241',
    isoCode: 'GA',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/ga.svg',
    nameEn: 'Gabon',
    nameAr: 'الجابون',
    emoji: '🇬🇦',
    secondFlag:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GA.svg',
    region: 'Africa',
    timezones: {
      'Africa/Libreville': '+01:00',
    },
    phoneLength: 7,
  },
  {
    dialCode: '+220',
    isoCode: 'GM',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/gm.svg',
    nameEn: 'Gambia',
    nameAr: 'غامبيا',
    emoji: '🇬🇲',
    secondFlag:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GM.svg',
    region: 'Africa',
    timezones: {
      'Africa/Banjul': '+00:00',
    },
    phoneLength: 7,
  },
  {
    dialCode: '+995',
    isoCode: 'GE',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/ge.svg',
    nameEn: 'Georgia',
    nameAr: 'جورجيا',
    emoji: '🇬🇪',
    secondFlag:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GE.svg',
    region: 'Asia',
    timezones: {
      'Asia/Tbilisi': '+04:00',
    },
    phoneLength: 9,
  },
  {
    dialCode: '+49',
    isoCode: 'DE',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/de.svg',
    nameEn: 'Germany',
    nameAr: 'ألمانيا',
    emoji: '🇩🇪',
    secondFlag:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/DE.svg',
    region: 'Europe',
    timezones: {
      'Europe/Berlin': '+02:00',
      'Europe/Busingen': '+02:00',
    },
    phoneLength: 10,
  },
  {
    dialCode: '+233',
    isoCode: 'GH',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/gh.svg',
    nameEn: 'Ghana',
    nameAr: 'غانا',
    emoji: '🇬🇭',
    secondFlag:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GH.svg',
    region: 'Africa',
    timezones: {
      'Africa/Accra': '+00:00',
    },
    phoneLength: 9,
  },
  {
    dialCode: '+350',
    isoCode: 'GI',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/gi.svg',
    nameEn: 'Gibraltar',
    nameAr: 'جبل طارق',
    emoji: '🇬🇮',
    secondFlag:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GI.svg',
    region: 'Europe',
    timezones: {
      'Europe/Gibraltar': '+02:00',
    },
    phoneLength: 8,
  },
  {
    dialCode: '+30',
    isoCode: 'GR',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/gr.svg',
    nameEn: 'Greece',
    nameAr: 'اليونان',
    emoji: '🇬🇷',
    secondFlag:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GR.svg',
    region: 'Europe',
    timezones: {
      'Europe/Athens': '+03:00',
    },
    phoneLength: 10,
  },
  {
    dialCode: '+299',
    isoCode: 'GL',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/gl.svg',
    nameEn: 'Greenland',
    nameAr: 'جرينلاند',
    emoji: '🇬🇱',
    secondFlag:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GL.svg',
    region: 'Americas',
    timezones: {
      'America/Danmarkshavn': '+00:00',
      'America/Nuuk': '-02:00',
      'America/Scoresbysund': '+00:00',
      'America/Thule': '-03:00',
    },
    phoneLength: 6,
  },
  {
    dialCode: '+1473',
    isoCode: 'GD',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/gd.svg',
    nameEn: 'Grenada',
    nameAr: 'غرينادا',
    emoji: '🇬🇩',
    secondFlag:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GD.svg',
    region: 'Americas',
    timezones: {
      'America/Grenada': '-04:00',
    },
    phoneLength: 10,
  },
  {
    dialCode: '+590',
    isoCode: 'GP',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/gp.svg',
    nameEn: 'Guadeloupe',
    nameAr: 'جوادلوب',
    emoji: '🇬🇵',
    secondFlag:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GP.svg',
    region: 'Americas',
    timezones: {
      'America/Guadeloupe': '-04:00',
    },
    phoneLength: 9,
  },
  {
    dialCode: '+1671',
    isoCode: 'GU',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/gu.svg',
    nameEn: 'Guam',
    nameAr: 'غوام',
    emoji: '🇬🇺',
    secondFlag:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GU.svg',
    region: 'Oceania',
    timezones: {
      'Pacific/Guam': '+10:00',
    },
    phoneLength: 10,
  },
  {
    dialCode: '+502',
    isoCode: 'GT',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/gt.svg',
    nameEn: 'Guatemala',
    nameAr: 'غواتيمالا',
    emoji: '🇬🇹',
    secondFlag:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GT.svg',
    region: 'Americas',
    timezones: {
      'America/Guatemala': '-06:00',
    },
    phoneLength: 8,
  },
  {
    dialCode: '+44-1481',
    isoCode: 'GG',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/gg.svg',
    nameEn: 'Guernsey',
    nameAr: 'غيرنسي',
    emoji: '🇬🇬',
    secondFlag:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GG.svg',
    region: 'Europe',
    timezones: {
      'Europe/Guernsey': '+01:00',
    },
    phoneLength: 10,
  },
  {
    dialCode: '+224',
    isoCode: 'GN',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/gn.svg',
    nameEn: 'Guinea',
    nameAr: 'غينيا',
    emoji: '🇬🇳',
    secondFlag:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GN.svg',
    region: 'Africa',
    timezones: {
      'Africa/Conakry': '+00:00',
    },
    phoneLength: 9,
  },
  {
    dialCode: '+245',
    isoCode: 'GW',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/gw.svg',
    nameEn: 'Guinea-Bissau',
    nameAr: 'غينيا بيساو',
    emoji: '🇬🇼',
    secondFlag:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GW.svg',
    region: 'Africa',
    timezones: {
      'Africa/Bissau': '+00:00',
    },
    phoneLength: 9,
  },
  {
    dialCode: '+592',
    isoCode: 'GY',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/gy.svg',
    nameEn: 'Guyana',
    nameAr: 'غيانا',
    emoji: '🇬🇾',
    secondFlag:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GY.svg',
    region: 'Americas',
    timezones: {
      'America/Guyana': '-04:00',
    },
    phoneLength: 7,
  },
  {
    dialCode: '+509',
    isoCode: 'HT',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/ht.svg',
    nameEn: 'Haiti',
    nameAr: 'هايتي',
    emoji: '🇭🇹',
    secondFlag:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/HT.svg',
    region: 'Americas',
    timezones: {
      'America/Port-au-Prince': '-04:00',
    },
    phoneLength: 8,
  },
  {
    dialCode: '+379',
    isoCode: 'VA',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/va.svg',
    nameEn: 'Holy See (Vatican City State)',
    nameAr: 'الكرسي الرسولي (دولة الفاتيكان)',
    emoji: '🇻🇦',
    secondFlag:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/VA.svg',
    region: 'Europe',
    timezones: {
      'Europe/Vatican': '+02:00',
    },
    phoneLength: 10,
  },
  {
    dialCode: '+504',
    isoCode: 'HN',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/hn.svg',
    nameEn: 'Honduras',
    nameAr: 'هندوراس',
    emoji: '🇭🇳',
    secondFlag:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/HN.svg',
    region: 'Americas',
    timezones: {
      'America/Tegucigalpa': '-06:00',
    },
    phoneLength: 8,
  },
  {
    dialCode: '+852',
    isoCode: 'HK',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/hk.svg',
    nameEn: 'Hong Kong',
    nameAr: 'هونغ كونغ',
    emoji: '🇭🇰',
    secondFlag:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/HK.svg',
    region: 'Asia',
    timezones: {
      'Asia/Hong_Kong': '+08:00',
    },
    phoneLength: 8,
  },
  {
    dialCode: '+36',
    isoCode: 'HU',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/hu.svg',
    nameEn: 'Hungary',
    nameAr: 'المجر',
    emoji: '🇭🇺',
    secondFlag:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/HU.svg',
    region: 'Europe',
    timezones: {
      'Europe/Budapest': '+02:00',
    },
    phoneLength: 9,
  },
  {
    dialCode: '+354',
    isoCode: 'IS',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/is.svg',
    nameEn: 'Iceland',
    nameAr: 'أيسلندا',
    emoji: '🇮🇸',
    secondFlag:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/IS.svg',
    region: 'Europe',
    timezones: {
      'Atlantic/Reykjavik': '+00:00',
    },
    phoneLength: 7,
  },
  {
    dialCode: '+91',
    isoCode: 'IN',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/in.svg',
    nameEn: 'India',
    nameAr: 'الهند',
    emoji: '🇮🇳',
    secondFlag:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/IN.svg',
    region: 'Asia',
    timezones: {
      'Asia/Kolkata': '+05:30',
    },
    phoneLength: 10,
  },
  {
    dialCode: '+62',
    isoCode: 'ID',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/id.svg',
    nameEn: 'Indonesia',
    nameAr: 'إندونيسيا',
    emoji: '🇮🇩',
    secondFlag:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/ID.svg',
    region: 'Asia',
    timezones: {
      'Asia/Jakarta': '+07:00',
      'Asia/Jayapura': '+09:00',
      'Asia/Makassar': '+08:00',
      'Asia/Pontianak': '+07:00',
    },
    phoneLength: 11,
  },
  {
    dialCode: '+98',
    isoCode: 'IR',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/ir.svg',
    nameEn: 'Iran',
    nameAr: 'إيران',
    emoji: '🇮🇷',
    secondFlag:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/IR.svg',
    region: 'Asia',
    timezones: {
      'Asia/Tehran': '+03:30',
    },
    phoneLength: 11,
  },
  {
    dialCode: '+964',
    isoCode: 'IQ',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/iq.svg',
    nameEn: 'Iraq',
    nameAr: 'العراق',
    emoji: '🇮🇶',
    secondFlag:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/IQ.svg',
    region: 'Asia',
    timezones: {
      'Asia/Baghdad': '+03:00',
    },
    phoneLength: 10,
  },
  {
    dialCode: '+353',
    isoCode: 'IE',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/ie.svg',
    nameEn: 'Ireland',
    nameAr: 'أيرلندا',
    emoji: '🇮🇪',
    secondFlag:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/IE.svg',
    region: 'Europe',
    timezones: {
      'Europe/Dublin': '+01:00',
    },
    phoneLength: 9,
  },
  {
    dialCode: '+44-1624',
    isoCode: 'IM',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/im.svg',
    nameEn: 'Isle of Man',
    nameAr: 'جزيرة مان',
    emoji: '🇮🇲',
    secondFlag:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/IM.svg',
    region: 'Europe',
    timezones: {
      'Europe/Isle_of_Man': '+01:00',
    },
    phoneLength: 10,
  },
  {
    dialCode: '+39',
    isoCode: 'IT',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/it.svg',
    nameEn: 'Italy',
    nameAr: 'إيطاليا',
    emoji: '🇮🇹',
    secondFlag:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/IT.svg',
    region: 'Europe',
    timezones: {
      'Europe/Rome': '+02:00',
    },
    phoneLength: 10,
  },
  {
    dialCode: '+1876',
    isoCode: 'JM',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/jm.svg',
    nameEn: 'Jamaica',
    nameAr: 'جامايكا',
    emoji: '🇯🇲',
    secondFlag:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/JM.svg',
    region: 'Americas',
    timezones: {
      'America/Jamaica': '-05:00',
    },
    phoneLength: 10,
  },
  {
    dialCode: '+81',
    isoCode: 'JP',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/jp.svg',
    nameEn: 'Japan',
    nameAr: 'اليابان',
    emoji: '🇯🇵',
    secondFlag:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/JP.svg',
    region: 'Asia',
    timezones: {
      'Asia/Tokyo': '+09:00',
    },
    phoneLength: 10,
  },
  {
    dialCode: '+44-1534',
    isoCode: 'JE',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/je.svg',
    nameEn: 'Jersey',
    nameAr: 'جيرسي',
    emoji: '🇯🇪',
    secondFlag:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/JE.svg',
    region: 'Europe',
    timezones: {
      'Europe/Jersey': '+01:00',
    },
    phoneLength: 10,
  },
  {
    dialCode: '+962',
    isoCode: 'JO',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/jo.svg',
    nameEn: 'Jordan',
    nameAr: 'الأردن',
    emoji: '🇯🇴',
    secondFlag:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/JO.svg',
    region: 'Asia',
    timezones: {
      'Asia/Amman': '+03:00',
    },
    phoneLength: 10,
  },
  {
    dialCode: '+77',
    isoCode: 'KZ',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/kz.svg',
    nameEn: 'Kazakhstan',
    nameAr: 'كازاخستان',
    emoji: '🇰🇿',
    secondFlag:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/KZ.svg',
    region: 'Asia',
    timezones: {
      'Asia/Almaty': '+06:00',
      'Asia/Aqtau': '+05:00',
      'Asia/Aqtobe': '+05:00',
      'Asia/Atyrau': '+05:00',
      'Asia/Oral': '+05:00',
      'Asia/Qostanay': '+06:00',
      'Asia/Qyzylorda': '+05:00',
    },
    phoneLength: 10,
  },
  {
    dialCode: '+254',
    isoCode: 'KE',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/ke.svg',
    nameEn: 'Kenya',
    nameAr: 'كينيا',
    emoji: '🇰🇪',
    secondFlag:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/KE.svg',
    region: 'Africa',
    timezones: {
      'Africa/Nairobi': '+03:00',
    },
    phoneLength: 10,
  },
  {
    dialCode: '+686',
    isoCode: 'KI',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/ki.svg',
    nameEn: 'Kiribati',
    nameAr: 'كيريباتي',
    emoji: '🇰🇮',
    secondFlag:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/KI.svg',
    region: 'Oceania',
    timezones: {
      'Pacific/Kanton': '+13:00',
      'Pacific/Kiritimati': '+14:00',
      'Pacific/Tarawa': '+12:00',
    },
    phoneLength: 8,
  },
  {
    dialCode: '+850',
    isoCode: 'KP',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/kp.svg',
    nameEn: "Korea, Democratic People's Republic of Korea",
    nameAr: 'جمهورية كوريا الشعبية الديمقراطية',
    emoji: '🇰🇵',
    secondFlag:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/KP.svg',
    region: 'Asia',
    timezones: {
      'Asia/Pyongyang': '+09:00',
    },
    phoneLength: 13,
  },
  {
    dialCode: '+82',
    isoCode: 'KR',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/kr.svg',
    nameEn: 'Korea, Republic of South Korea',
    nameAr: 'جمهورية كوريا الجنوبية',
    emoji: '🇰🇷',
    secondFlag:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/KR.svg',
    region: 'Asia',
    timezones: {
      'Asia/Seoul': '+09:00',
    },
    phoneLength: 8,
  },
  {
    dialCode: '+965',
    isoCode: 'KW',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/kw.svg',
    nameEn: 'Kuwait',
    nameAr: 'الكويت',
    emoji: '🇰🇼',
    secondFlag:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/KW.svg',
    region: 'Asia',
    timezones: {
      'Asia/Kuwait': '+03:00',
    },
    phoneLength: 8,
  },
  {
    dialCode: '+996',
    isoCode: 'KG',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/kg.svg',
    nameEn: 'Kyrgyzstan',
    nameAr: 'قيرغيزستان',
    emoji: '🇰🇬',
    secondFlag:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/KG.svg',
    region: 'Asia',
    timezones: {
      'Asia/Bishkek': '+06:00',
    },
    phoneLength: 9,
  },
  {
    dialCode: '+856',
    isoCode: 'LA',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/la.svg',
    nameEn: 'Laos',
    nameAr: 'لاوس',
    emoji: '🇱🇦',
    secondFlag:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/LA.svg',
    region: 'Asia',
    timezones: {
      'Asia/Vientiane': '+07:00',
    },
    phoneLength: 9,
  },
  {
    dialCode: '+371',
    isoCode: 'LV',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/lv.svg',
    nameEn: 'Latvia',
    nameAr: 'لاتفيا',
    emoji: '🇱🇻',
    secondFlag:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/LV.svg',
    region: 'Europe',
    timezones: {
      'Europe/Riga': '+03:00',
    },
    phoneLength: 8,
  },
  {
    dialCode: '+961',
    isoCode: 'LB',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/lb.svg',
    nameEn: 'Lebanon',
    nameAr: 'لبنان',
    emoji: '🇱🇧',
    secondFlag:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/LB.svg',
    region: 'Asia',
    timezones: {
      'Asia/Beirut': '+03:00',
    },
    phoneLength: 8,
  },
  {
    dialCode: '+266',
    isoCode: 'LS',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/ls.svg',
    nameEn: 'Lesotho',
    nameAr: 'ليسوتو',
    emoji: '🇱🇸',
    secondFlag:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/LS.svg',
    region: 'Africa',
    timezones: {
      'Africa/Maseru': '+02:00',
    },
    phoneLength: 8,
  },
  {
    dialCode: '+231',
    isoCode: 'LR',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/lr.svg',
    nameEn: 'Liberia',
    nameAr: 'ليبيريا',
    emoji: '🇱🇷',
    secondFlag:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/LR.svg',
    region: 'Africa',
    timezones: {
      'Africa/Monrovia': '+00:00',
    },
    phoneLength: 9,
  },
  {
    dialCode: '+218',
    isoCode: 'LY',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/ly.svg',
    nameEn: 'Libya',
    nameAr: 'ليبيا',
    emoji: '🇱🇾',
    secondFlag:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/LY.svg',
    region: 'Africa',
    timezones: {
      'Africa/Tripoli': '+02:00',
    },
    phoneLength: 10,
  },
  {
    dialCode: '+423',
    isoCode: 'LI',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/li.svg',
    nameEn: 'Liechtenstein',
    nameAr: 'ليختنشتاين',
    emoji: '🇱🇮',
    secondFlag:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/LI.svg',
    region: 'Europe',
    timezones: {
      'Europe/Vaduz': '+02:00',
    },
    phoneLength: 7,
  },
  {
    dialCode: '+370',
    isoCode: 'LT',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/lt.svg',
    nameEn: 'Lithuania',
    nameAr: 'ليتوانيا',
    emoji: '🇱🇹',
    secondFlag:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/LT.svg',
    region: 'Europe',
    timezones: {
      'Europe/Vilnius': '+03:00',
    },
    phoneLength: 8,
  },
  {
    dialCode: '+352',
    isoCode: 'LU',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/lu.svg',
    nameEn: 'Luxembourg',
    nameAr: 'لوكسمبورغ',
    emoji: '🇱🇺',
    secondFlag:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/LU.svg',
    region: 'Europe',
    timezones: {
      'Europe/Luxembourg': '+02:00',
    },
    phoneLength: 9,
  },
  {
    dialCode: '+853',
    isoCode: 'MO',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/mo.svg',
    nameEn: 'Macau',
    nameAr: 'ماكاو',
    emoji: '🇲🇴',
    secondFlag:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MO.svg',
    region: 'Asia',
    timezones: {
      'Asia/Macau': '+08:00',
    },
    phoneLength: 8,
  },
  {
    dialCode: '+389',
    isoCode: 'MK',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/mk.svg',
    nameEn: 'North Macedonia',
    nameAr: 'مقدونيا الشمالية',
    emoji: '🇲🇰',
    secondFlag:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MK.svg',
    region: 'Europe',
    timezones: {
      'Europe/Skopje': '+02:00',
    },
    phoneLength: 8,
  },
  {
    dialCode: '+261',
    isoCode: 'MG',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/mg.svg',
    nameEn: 'Madagascar',
    nameAr: 'مدغشقر',
    emoji: '🇲🇬',
    secondFlag:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MG.svg',
    region: 'Africa',
    timezones: {
      'Indian/Antananarivo': '+03:00',
    },
    phoneLength: 7,
  },
  {
    dialCode: '+265',
    isoCode: 'MW',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/mw.svg',
    nameEn: 'Malawi',
    nameAr: 'ملاوي',
    emoji: '🇲🇼',
    secondFlag:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MW.svg',
    region: 'Africa',
    timezones: {
      'Africa/Blantyre': '+02:00',
    },
    phoneLength: 9,
  },
  {
    dialCode: '+60',
    isoCode: 'MY',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/my.svg',
    nameEn: 'Malaysia',
    nameAr: 'ماليزيا',
    emoji: '🇲🇾',
    secondFlag:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MY.svg',
    region: 'Asia',
    timezones: {
      'Asia/Kuala_Lumpur': '+08:00',
      'Asia/Kuching': '+08:00',
    },
    phoneLength: 7,
  },
  {
    dialCode: '+960',
    isoCode: 'MV',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/mv.svg',
    nameEn: 'Maldives',
    nameAr: 'جزر المالديف',
    emoji: '🇲🇻',
    secondFlag:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MV.svg',
    region: 'Asia',
    timezones: {
      'Indian/Maldives': '+05:00',
    },
    phoneLength: 7,
  },
  {
    dialCode: '+223',
    isoCode: 'ML',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/ml.svg',
    nameEn: 'Mali',
    nameAr: 'مالي',
    emoji: '🇲🇱',
    secondFlag:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/ML.svg',
    region: 'Africa',
    timezones: {
      'Africa/Bamako': '+00:00',
    },
    phoneLength: 8,
  },
  {
    dialCode: '+356',
    isoCode: 'MT',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/mt.svg',
    nameEn: 'Malta',
    nameAr: 'مالطا',
    emoji: '🇲🇹',
    secondFlag:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MT.svg',
    region: 'Europe',
    timezones: {
      'Europe/Malta': '+02:00',
    },
    phoneLength: 8,
  },
  {
    dialCode: '+692',
    isoCode: 'MH',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/mh.svg',
    nameEn: 'Marshall Islands',
    nameAr: 'جزر مارشال',
    emoji: '🇲🇭',
    secondFlag:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MH.svg',
    region: 'Oceania',
    timezones: {
      'Pacific/Kwajalein': '+12:00',
      'Pacific/Majuro': '+12:00',
    },
    phoneLength: 7,
  },
  {
    dialCode: '+596',
    isoCode: 'MQ',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/mq.svg',
    nameEn: 'Martinique',
    nameAr: 'مارتينيك',
    emoji: '🇲🇶',
    secondFlag:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MQ.svg',
    region: 'Americas',
    timezones: {
      'America/Martinique': '-04:00',
    },
    phoneLength: 9,
  },
  {
    dialCode: '+222',
    isoCode: 'MR',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/mr.svg',
    nameEn: 'Mauritania',
    nameAr: 'موريتانيا',
    emoji: '🇲🇷',
    secondFlag:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MR.svg',
    region: 'Africa',
    timezones: {
      'Africa/Nouakchott': '+00:00',
    },
    phoneLength: 8,
  },
  {
    dialCode: '+230',
    isoCode: 'MU',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/mu.svg',
    nameEn: 'Mauritius',
    nameAr: 'موريشيوس',
    emoji: '🇲🇺',
    secondFlag:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MU.svg',
    region: 'Africa',
    timezones: {
      'Indian/Mauritius': '+04:00',
    },
    phoneLength: 8,
  },
  {
    dialCode: '+262',
    isoCode: 'YT',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/yt.svg',
    nameEn: 'Mayotte',
    nameAr: 'مايوت',
    emoji: '🇾🇹',
    secondFlag:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/YT.svg',
    region: 'Africa',
    timezones: {
      'Indian/Mayotte': '+03:00',
    },
    phoneLength: 9,
  },
  {
    dialCode: '+52',
    isoCode: 'MX',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/mx.svg',
    nameEn: 'Mexico',
    nameAr: 'المكسيك',
    emoji: '🇲🇽',
    secondFlag:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MX.svg',
    region: 'Americas',
    timezones: {
      'America/Bahia_Banderas': '-06:00',
      'America/Cancun': '-05:00',
      'America/Chihuahua': '-06:00',
      'America/Ciudad_Juarez': '-06:00',
      'America/Hermosillo': '-07:00',
      'America/Matamoros': '-05:00',
      'America/Mazatlan': '-07:00',
      'America/Merida': '-06:00',
      'America/Mexico_City': '-06:00',
      'America/Monterrey': '-06:00',
      'America/Ojinaga': '-05:00',
      'America/Tijuana': '-07:00',
    },
    phoneLength: 10,
  },
  {
    dialCode: '+691',
    isoCode: 'FM',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/fm.svg',
    nameEn: 'Micronesia, Federated States of Micronesia',
    nameAr: 'ولايات ميكرونيزيا الموحدة',
    emoji: '🇫🇲',
    secondFlag:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/FM.svg',
    region: 'Oceania',
    timezones: {
      'Pacific/Chuuk': '+10:00',
      'Pacific/Kosrae': '+11:00',
      'Pacific/Pohnpei': '+11:00',
    },
    phoneLength: 7,
  },
  {
    dialCode: '+373',
    isoCode: 'MD',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/md.svg',
    nameEn: 'Moldova',
    nameAr: 'مولدوفا',
    emoji: '🇲🇩',
    secondFlag:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MD.svg',
    region: 'Europe',
    timezones: {
      'Europe/Chisinau': '+03:00',
    },
    phoneLength: 8,
  },
  {
    dialCode: '+377',
    isoCode: 'MC',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/mc.svg',
    nameEn: 'Monaco',
    nameAr: 'موناكو',
    emoji: '🇲🇨',
    secondFlag:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MC.svg',
    region: 'Europe',
    timezones: {
      'Europe/Monaco': '+02:00',
    },
    phoneLength: 8,
  },
  {
    dialCode: '+976',
    isoCode: 'MN',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/mn.svg',
    nameEn: 'Mongolia',
    nameAr: 'منغوليا',
    emoji: '🇲🇳',
    secondFlag:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MN.svg',
    region: 'Asia',
    timezones: {
      'Asia/Choibalsan': '+08:00',
      'Asia/Hovd': '+07:00',
      'Asia/Ulaanbaatar': '+08:00',
    },
    phoneLength: 8,
  },
  {
    dialCode: '+382',
    isoCode: 'ME',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/me.svg',
    nameEn: 'Montenegro',
    nameAr: 'الجبل الأسود',
    emoji: '🇲🇪',
    secondFlag:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/ME.svg',
    region: 'Europe',
    timezones: {
      'Europe/Podgorica': '+02:00',
    },
    phoneLength: 8,
  },
  {
    dialCode: '+1664',
    isoCode: 'MS',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/ms.svg',
    nameEn: 'Montserrat',
    nameAr: 'مونتسيرات',
    emoji: '🇲🇸',
    secondFlag:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MS.svg',
    region: 'Americas',
    timezones: {
      'America/Montserrat': '-04:00',
    },
    phoneLength: 10,
  },
  {
    dialCode: '+212',
    isoCode: 'MA',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/ma.svg',
    nameEn: 'Morocco',
    nameAr: 'المغرب',
    emoji: '🇲🇦',
    secondFlag:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MA.svg',
    region: 'Africa',
    timezones: {
      'Africa/Casablanca': '+00:00',
      'Africa/El_Aaiun': '+00:00',
    },
    phoneLength: 9,
  },
  {
    dialCode: '+258',
    isoCode: 'MZ',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/mz.svg',
    nameEn: 'Mozambique',
    nameAr: 'موزمبيق',
    emoji: '🇲🇿',
    secondFlag:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MZ.svg',
    region: 'Africa',
    timezones: {
      'Africa/Maputo': '+02:00',
    },
    phoneLength: 12,
  },
  {
    dialCode: '+95',
    isoCode: 'MM',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/mm.svg',
    nameEn: 'Myanmar',
    nameAr: 'ميانمار',
    emoji: '🇲🇲',
    secondFlag:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MM.svg',
    region: 'Asia',
    timezones: {
      'Asia/Yangon': '+06:30',
    },
    phoneLength: 11,
  },
  {
    dialCode: '+264',
    isoCode: 'NA',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/na.svg',
    nameEn: 'Namibia',
    nameAr: 'ناميبيا',
    emoji: '🇳🇦',
    secondFlag:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/NA.svg',
    region: 'Africa',
    timezones: {
      'Africa/Windhoek': '+02:00',
    },
    phoneLength: 7,
  },
  {
    dialCode: '+674',
    isoCode: 'NR',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/nr.svg',
    nameEn: 'Nauru',
    nameAr: 'ناورو',
    emoji: '🇳🇷',
    secondFlag:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/NR.svg',
    region: 'Oceania',
    timezones: {
      'Pacific/Nauru': '+12:00',
    },
    phoneLength: 7,
  },
  {
    dialCode: '+977',
    isoCode: 'NP',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/np.svg',
    nameEn: 'Nepal',
    nameAr: 'نيبال',
    emoji: '🇳🇵',
    secondFlag:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/NP.svg',
    region: 'Asia',
    timezones: {
      'Asia/Kathmandu': '+05:45',
    },
    phoneLength: 10,
  },
  {
    dialCode: '+31',
    isoCode: 'NL',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/nl.svg',
    nameEn: 'Netherlands',
    nameAr: 'هولندا',
    emoji: '🇳🇱',
    secondFlag:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/NL.svg',
    region: 'Europe',
    timezones: {
      'Europe/Amsterdam': '+02:00',
    },
    phoneLength: 9,
  },
  {
    dialCode: '+687',
    isoCode: 'NC',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/nc.svg',
    nameEn: 'New Caledonia',
    nameAr: 'كاليدونيا الجديدة',
    emoji: '🇳🇨',
    secondFlag:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/NC.svg',
    region: 'Oceania',
    timezones: {
      'Pacific/Noumea': '+11:00',
    },
    phoneLength: 6,
  },
  {
    dialCode: '+64',
    isoCode: 'NZ',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/nz.svg',
    nameEn: 'New Zealand',
    nameAr: 'نيوزيلندا',
    emoji: '🇳🇿',
    secondFlag:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/NZ.svg',
    region: 'Oceania',
    timezones: {
      'Pacific/Auckland': '+12:00',
      'Pacific/Chatham': '+12:45',
    },
    phoneLength: 9,
  },
  {
    dialCode: '+505',
    isoCode: 'NI',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/ni.svg',
    nameEn: 'Nicaragua',
    nameAr: 'نيكاراغوا',
    emoji: '🇳🇮',
    secondFlag:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/NI.svg',
    region: 'Americas',
    timezones: {
      'America/Managua': '-06:00',
    },
    phoneLength: 8,
  },
  {
    dialCode: '+227',
    isoCode: 'NE',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/ne.svg',
    nameEn: 'Niger',
    nameAr: 'النيجر',
    emoji: '🇳🇪',
    secondFlag:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/NE.svg',
    region: 'Africa',
    timezones: {
      'Africa/Niamey': '+01:00',
    },
    phoneLength: 8,
  },
  {
    dialCode: '+234',
    isoCode: 'NG',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/ng.svg',
    nameEn: 'Nigeria',
    nameAr: 'نيجيريا',
    emoji: '🇳🇬',
    secondFlag:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/NG.svg',
    region: 'Africa',
    timezones: {
      'Africa/Lagos': '+01:00',
    },
    phoneLength: 8,
  },
  {
    dialCode: '+683',
    isoCode: 'NU',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/nu.svg',
    nameEn: 'Niue',
    nameAr: 'نيوي',
    emoji: '🇳🇺',
    secondFlag:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/NU.svg',
    region: 'Oceania',
    timezones: {
      'Pacific/Niue': '-11:00',
    },
    phoneLength: 4,
  },
  {
    dialCode: '+672',
    isoCode: 'NF',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/nf.svg',
    nameEn: 'Norfolk Island',
    nameAr: 'جزيرة نورفولك',
    emoji: '🇳🇫',
    secondFlag:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/NF.svg',
    region: 'Oceania',
    timezones: {
      'Pacific/Norfolk': '+11:00',
    },
    phoneLength: 6,
  },
  {
    dialCode: '+1670',
    isoCode: 'MP',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/mp.svg',
    nameEn: 'Northern Mariana Islands',
    nameAr: 'جزر ماريانا الشمالية',
    emoji: '🇲🇵',
    secondFlag:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MP.svg',
    region: 'Oceania',
    timezones: {
      'Pacific/Saipan': '+10:00',
    },
    phoneLength: 7,
  },
  {
    dialCode: '+47',
    isoCode: 'NO',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/no.svg',
    nameEn: 'Norway',
    nameAr: 'النرويج',
    emoji: '🇳🇴',
    secondFlag:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/NO.svg',
    region: 'Europe',
    timezones: {
      'Europe/Oslo': '+02:00',
    },
    phoneLength: 8,
  },
  {
    dialCode: '+968',
    isoCode: 'OM',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/om.svg',
    nameEn: 'Oman',
    nameAr: 'عمان',
    emoji: '🇴🇲',
    secondFlag:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/OM.svg',
    region: 'Asia',
    timezones: {
      'Asia/Muscat': '+04:00',
    },
    phoneLength: 8,
  },
  {
    dialCode: '+92',
    isoCode: 'PK',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/pk.svg',
    nameEn: 'Pakistan',
    nameAr: 'باكستان',
    emoji: '🇵🇰',
    secondFlag:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PK.svg',
    region: 'Asia',
    timezones: {
      'Asia/Karachi': '+05:00',
    },
    phoneLength: 10,
  },
  {
    dialCode: '+680',
    isoCode: 'PW',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/pw.svg',
    nameEn: 'Palau',
    nameAr: 'بالاو',
    emoji: '🇵🇼',
    secondFlag:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PW.svg',
    region: 'Oceania',
    timezones: {
      'Pacific/Palau': '+09:00',
    },
    phoneLength: 7,
  },
  {
    dialCode: '+972',
    isoCode: 'PS',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/ps.svg',
    nameEn: 'Palestine',
    nameAr: 'فلسطين',
    emoji: '🇵🇸',
    secondFlag:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PS.svg',
    region: 'Asia',
    timezones: {
      'Asia/Gaza': '+02:00',
      'Asia/Hebron': '+02:00',
      'Asia/Jerusalem': '+03:00',
    },
    phoneLength: 10,
  },
  {
    dialCode: '+970',
    isoCode: 'PS',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/ps.svg',
    nameEn: 'Palestine',
    nameAr: 'فلسطين',
    emoji: '🇵🇸',
    secondFlag:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PS.svg',
    region: 'Asia',
    timezones: {
      'Asia/Gaza': '+02:00',
      'Asia/Hebron': '+02:00',
      'Asia/Jerusalem': '+03:00',
    },
    phoneLength: 10,
  },
  {
    dialCode: '+507',
    isoCode: 'PA',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/pa.svg',
    nameEn: 'Panama',
    nameAr: 'بنما',
    emoji: '🇵🇦',
    secondFlag:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PA.svg',
    region: 'Americas',
    timezones: {
      'America/Panama': '-05:00',
    },
    phoneLength: 8,
  },
  {
    dialCode: '+675',
    isoCode: 'PG',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/pg.svg',
    nameEn: 'Papua New Guinea',
    nameAr: 'بابوا غينيا الجديدة',
    emoji: '🇵🇬',
    secondFlag:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PG.svg',
    region: 'Oceania',
    timezones: {
      'Pacific/Bougainville': '+11:00',
      'Pacific/Port_Moresby': '+10:00',
    },
    phoneLength: 8,
  },
  {
    dialCode: '+595',
    isoCode: 'PY',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/py.svg',
    nameEn: 'Paraguay',
    nameAr: 'باراغواي',
    emoji: '🇵🇾',
    secondFlag:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PY.svg',
    region: 'Americas',
    timezones: {
      'America/Asuncion': '-04:00',
    },
    phoneLength: 9,
  },
  {
    dialCode: '+51',
    isoCode: 'PE',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/pe.svg',
    nameEn: 'Peru',
    nameAr: 'بيرو',
    emoji: '🇵🇪',
    secondFlag:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PE.svg',
    region: 'Americas',
    timezones: {
      'America/Lima': '-05:00',
    },
    phoneLength: 9,
  },
  {
    dialCode: '+63',
    isoCode: 'PH',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/ph.svg',
    nameEn: 'Philippines',
    nameAr: 'الفلبين',
    emoji: '🇵🇭',
    secondFlag:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PH.svg',
    region: 'Asia',
    timezones: {
      'Asia/Manila': '+08:00',
    },
    phoneLength: 10,
  },
  {
    dialCode: '+872',
    isoCode: 'PN',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/pn.svg',
    nameEn: 'Pitcairn',
    nameAr: 'بيتكيرن',
    emoji: '🇵🇳',
    secondFlag:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PN.svg',
    region: 'Oceania',
    timezones: {
      'Pacific/Pitcairn': '-08:00',
    },
    phoneLength: 9,
  },
  {
    dialCode: '+48',
    isoCode: 'PL',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/pl.svg',
    nameEn: 'Poland',
    nameAr: 'بولندا',
    emoji: '🇵🇱',
    secondFlag:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PL.svg',
    region: 'Europe',
    timezones: {
      'Europe/Warsaw': '+02:00',
    },
    phoneLength: 9,
  },
  {
    dialCode: '+351',
    isoCode: 'PT',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/pt.svg',
    nameEn: 'Portugal',
    nameAr: 'البرتغال',
    emoji: '🇵🇹',
    secondFlag:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PT.svg',
    region: 'Europe',
    timezones: {
      'Atlantic/Azores': '+00:00',
      'Atlantic/Madeira': '+01:00',
      'Europe/Lisbon': '+01:00',
    },
    phoneLength: 9,
  },
  {
    dialCode: '+1939',
    isoCode: 'PR',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/pr.svg',
    nameEn: 'Puerto Rico',
    nameAr: 'بورتوريكو',
    emoji: '🇵🇷',
    secondFlag:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PR.svg',
    region: 'Americas',
    timezones: {
      'America/Puerto_Rico': '-04:00',
    },
    phoneLength: 10,
  },
  {
    dialCode: '+974',
    isoCode: 'QA',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/qa.svg',
    nameEn: 'Qatar',
    nameAr: 'قطر',
    emoji: '🇶🇦',
    secondFlag:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/QA.svg',
    region: 'Asia',
    timezones: {
      'Asia/Qatar': '+03:00',
    },
    phoneLength: 8,
  },
  {
    dialCode: '+40',
    isoCode: 'RO',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/ro.svg',
    nameEn: 'Romania',
    nameAr: ' رومانيا ',
    emoji: '🇷🇴',
    secondFlag:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/RO.svg',
    region: 'Europe',
    timezones: {
      'Europe/Bucharest': '+03:00',
    },
    phoneLength: 10,
  },
  {
    dialCode: '+7',
    isoCode: 'RU',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/ru.svg',
    nameEn: 'Russia',
    nameAr: 'روسيا',
    emoji: '🇷🇺',
    secondFlag:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/RU.svg',
    region: 'Europe',
    timezones: {
      'Asia/Anadyr': '+12:00',
      'Asia/Barnaul': '+07:00',
      'Asia/Chita': '+09:00',
      'Asia/Irkutsk': '+08:00',
      'Asia/Kamchatka': '+12:00',
      'Asia/Khandyga': '+09:00',
      'Asia/Krasnoyarsk': '+07:00',
      'Asia/Magadan': '+11:00',
      'Asia/Novokuznetsk': '+07:00',
      'Asia/Novosibirsk': '+07:00',
      'Asia/Omsk': '+06:00',
      'Asia/Sakhalin': '+11:00',
      'Asia/Srednekolymsk': '+11:00',
      'Asia/Tomsk': '+07:00',
      'Asia/Ust-Nera': '+10:00',
      'Asia/Vladivostok': '+10:00',
      'Asia/Yakutsk': '+09:00',
      'Asia/Yekaterinburg': '+05:00',
      'Europe/Astrakhan': '+04:00',
      'Europe/Kaliningrad': '+02:00',
      'Europe/Kirov': '+03:00',
      'Europe/Moscow': '+03:00',
      'Europe/Samara': '+04:00',
      'Europe/Saratov': '+04:00',
      'Europe/Ulyanovsk': '+04:00',
      'Europe/Volgograd': '+03:00',
    },
    phoneLength: 10,
  },
  {
    dialCode: '+250',
    isoCode: 'RW',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/rw.svg',
    nameEn: 'Rwanda',
    nameAr: 'رواندا',
    emoji: '🇷🇼',
    secondFlag:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/RW.svg',
    region: 'Africa',
    timezones: {
      'Africa/Kigali': '+02:00',
    },
    phoneLength: 9,
  },
  {
    dialCode: '+262',
    isoCode: 'RE',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/re.svg',
    nameEn: 'Reunion',
    nameAr: 'ريونيون',
    emoji: '🇷🇪',
    secondFlag:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/RE.svg',
    region: 'Africa',
    timezones: {
      'Indian/Reunion': '+04:00',
    },
    phoneLength: 10,
  },
  {
    dialCode: '+590',
    isoCode: 'BL',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/bl.svg',
    nameEn: 'Saint Barthelemy',
    nameAr: 'سانت بارتيليمي',
    emoji: '🇧🇱',
    secondFlag:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BL.svg',
    region: 'Americas',
    timezones: {
      'America/St_Barthelemy': '-04:00',
    },
    phoneLength: 9,
  },
  {
    dialCode: '+290',
    isoCode: 'SH',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/sh.svg',
    nameEn: 'Saint Helena, Ascension and Tristan Da Cunha',
    nameAr: 'سانت هيلانة وأسنسيون وتريستان دا كونا',
    emoji: '🇸🇭',
    secondFlag:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SH.svg',
    region: 'Africa',
    timezones: {
      'Atlantic/St_Helena': '+00:00',
    },
    phoneLength: 4,
  },
  {
    dialCode: '+1869',
    isoCode: 'KN',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/kn.svg',
    nameEn: 'Saint Kitts and Nevis',
    nameAr: 'سانت كيتس ونيفيس',
    emoji: '🇰🇳',
    secondFlag:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/KN.svg',
    region: 'Americas',
    timezones: {
      'America/St_Kitts': '-04:00',
    },
    phoneLength: 10,
  },
  {
    dialCode: '+1758',
    isoCode: 'LC',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/lc.svg',
    nameEn: 'Saint Lucia',
    nameAr: 'سانت لوسيا',
    emoji: '🇱🇨',
    secondFlag:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/LC.svg',
    region: 'Americas',
    timezones: {
      'America/St_Lucia': '-04:00',
    },
    phoneLength: 7,
  },
  {
    dialCode: '+590',
    isoCode: 'MF',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/mf.svg',
    nameEn: 'Saint Martin',
    nameAr: 'سانت مارتن',
    emoji: '🇲🇫',
    secondFlag:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MF.svg',
    region: 'Americas',
    timezones: {
      'America/Marigot': '-04:00',
    },
    phoneLength: 6,
  },
  {
    dialCode: '+508',
    isoCode: 'PM',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/pm.svg',
    nameEn: 'Saint Pierre and Miquelon',
    nameAr: 'سان بيير وميكلون',
    emoji: '🇵🇲',
    secondFlag:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PM.svg',
    region: 'Americas',
    timezones: {
      'America/Miquelon': '-02:00',
    },
    phoneLength: 6,
  },
  {
    dialCode: '+1784',
    isoCode: 'VC',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/vc.svg',
    nameEn: 'Saint Vincent and the Grenadines',
    nameAr: 'سانت فنسنت وجزر غرينادين',
    emoji: '🇻🇨',
    secondFlag:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/VC.svg',
    region: 'Americas',
    timezones: {
      'America/St_Vincent': '-04:00',
    },
    phoneLength: 7,
  },
  {
    dialCode: '+685',
    isoCode: 'WS',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/ws.svg',
    nameEn: 'Samoa',
    nameAr: 'ساموا',
    emoji: '🇼🇸',
    secondFlag:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/WS.svg',
    region: 'Oceania',
    timezones: {
      'Pacific/Apia': '+13:00',
    },
    phoneLength: 7,
  },
  {
    dialCode: '+378',
    isoCode: 'SM',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/sm.svg',
    nameEn: 'San Marino',
    nameAr: 'سان مارينو',
    emoji: '🇸🇲',
    secondFlag:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SM.svg',
    region: 'Europe',
    timezones: {
      'Europe/San_Marino': '+02:00',
    },
    phoneLength: 10,
  },
  {
    dialCode: '+239',
    isoCode: 'ST',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/st.svg',
    nameEn: 'Sao Tome and Principe',
    nameAr: 'ساو تومي وبرينسيبي',
    emoji: '🇸🇹',
    secondFlag:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/ST.svg',
    region: 'Africa',
    timezones: {
      'Africa/Sao_Tome': '+00:00',
    },
    phoneLength: 7,
  },
  {
    dialCode: '+966',
    isoCode: 'SA',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/sa.svg',
    nameEn: 'Saudi Arabia',
    nameAr: 'المملكة العربية السعودية',
    emoji: '🇸🇦',
    secondFlag:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SA.svg',
    region: 'Asia',
    timezones: {
      'Asia/Riyadh': '+03:00',
    },
    phoneLength: 9,
  },
  {
    dialCode: '+221',
    isoCode: 'SN',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/sn.svg',
    nameEn: 'Senegal',
    nameAr: 'السنغال',
    emoji: '🇸🇳',
    secondFlag:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SN.svg',
    region: 'Africa',
    timezones: {
      'Africa/Dakar': '+00:00',
    },
    phoneLength: 9,
  },
  {
    dialCode: '+381',
    isoCode: 'RS',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/rs.svg',
    nameEn: 'Serbia',
    nameAr: 'صربيا',
    emoji: '🇷🇸',
    secondFlag:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/RS.svg',
    region: 'Europe',
    timezones: {
      'Europe/Belgrade': '+02:00',
    },
    phoneLength: 9,
  },
  {
    dialCode: '+248',
    isoCode: 'SC',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/sc.svg',
    nameEn: 'Seychelles',
    nameAr: 'سيشيل',
    emoji: '🇸🇨',
    secondFlag:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SC.svg',
    region: 'Africa',
    timezones: {
      'Indian/Mahe': '+04:00',
    },
    phoneLength: 7,
  },
  {
    dialCode: '+232',
    isoCode: 'SL',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/sl.svg',
    nameEn: 'Sierra Leone',
    nameAr: 'سيراليون',
    emoji: '🇸🇱',
    secondFlag:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SL.svg',
    region: 'Africa',
    timezones: {
      'Africa/Freetown': '+00:00',
    },
    phoneLength: 8,
  },
  {
    dialCode: '+65',
    isoCode: 'SG',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/sg.svg',
    nameEn: 'Singapore',
    nameAr: 'سنغافورة',
    emoji: '🇸🇬',
    secondFlag:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SG.svg',
    region: 'Asia',
    timezones: {
      'Asia/Singapore': '+08:00',
    },
    phoneLength: 8,
  },
  {
    dialCode: '+1721',
    isoCode: 'SX',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/sx.svg',
    nameEn: 'Sint Maarten',
    nameAr: 'سينت مارتن',
    emoji: '🇸🇽',
    secondFlag:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SX.svg',
    region: 'Americas',
    timezones: {
      'America/Lower_Princes': '-04:00',
    },
    phoneLength: 10,
  },
  {
    dialCode: '+421',
    isoCode: 'SK',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/sk.svg',
    nameEn: 'Slovakia',
    nameAr: 'سلوفاكيا',
    emoji: '🇸🇰',
    secondFlag:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SK.svg',
    region: 'Europe',
    timezones: {
      'Europe/Bratislava': '+02:00',
    },
    phoneLength: 9,
  },
  {
    dialCode: '+386',
    isoCode: 'SI',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/si.svg',
    nameEn: 'Slovenia',
    nameAr: 'سلوفينيا',
    emoji: '🇸🇮',
    secondFlag:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SI.svg',
    region: 'Europe',
    timezones: {
      'Europe/Ljubljana': '+02:00',
    },
    phoneLength: 9,
  },
  {
    dialCode: '+677',
    isoCode: 'SB',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/sb.svg',
    nameEn: 'Solomon Islands',
    nameAr: 'جزر سليمان',
    emoji: '🇸🇧',
    secondFlag:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SB.svg',
    region: 'Oceania',
    timezones: {
      'Pacific/Guadalcanal': '+11:00',
    },
    phoneLength: 7,
  },
  {
    dialCode: '+252',
    isoCode: 'SO',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/so.svg',
    nameEn: 'Somalia',
    nameAr: 'الصومال',
    emoji: '🇸🇴',
    secondFlag:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SO.svg',
    region: 'Africa',
    timezones: {
      'Africa/Mogadishu': '+03:00',
    },
    phoneLength: 9,
  },
  {
    dialCode: '+27',
    isoCode: 'ZA',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/za.svg',
    nameEn: 'South Africa',
    nameAr: 'جنوب إفريقيا',
    emoji: '🇿🇦',
    secondFlag:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/ZA.svg',
    region: 'Africa',
    timezones: {
      'Africa/Johannesburg': '+02:00',
    },
    phoneLength: 9,
  },
  {
    dialCode: '+500',
    isoCode: 'GS',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/gs.svg',
    nameEn: 'South Georgia and the South Sandwich Islands',
    nameAr: 'جورجيا الجنوبية وجزر ساندويتش الجنوبية',
    emoji: '🇬🇸',
    secondFlag:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GS.svg',
    region: 'Americas',
    timezones: {
      'Atlantic/South_Georgia': '-02:00',
    },
    phoneLength: 5,
  },
  {
    dialCode: '+211',
    isoCode: 'SS',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/ss.svg',
    nameEn: 'South Sudan',
    nameAr: 'جنوب السودان',
    emoji: '🇸🇸',
    secondFlag:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SS.svg',
    region: 'Africa',
    timezones: {
      'Africa/Juba': '+02:00',
    },
    phoneLength: 7,
  },
  {
    dialCode: '+34',
    isoCode: 'ES',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/es.svg',
    nameEn: 'Spain',
    nameAr: 'إسبانيا',
    emoji: '🇪🇸',
    secondFlag:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/ES.svg',
    region: 'Europe',
    timezones: {
      'Africa/Ceuta': '+02:00',
      'Atlantic/Canary': '+01:00',
      'Europe/Madrid': '+02:00',
    },
    phoneLength: 9,
  },
  {
    dialCode: '+94',
    isoCode: 'LK',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/lk.svg',
    nameEn: 'Sri Lanka',
    nameAr: 'سريلانكا',
    emoji: '🇱🇰',
    secondFlag:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/LK.svg',
    region: 'Asia',
    timezones: {
      'Asia/Colombo': '+05:30',
    },
    phoneLength: 7,
  },
  {
    dialCode: '+249',
    isoCode: 'SD',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/sd.svg',
    nameEn: 'Sudan',
    nameAr: 'السودان',
    emoji: '🇸🇩',
    secondFlag:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SD.svg',
    region: 'Africa',
    timezones: {
      'Africa/Khartoum': '+02:00',
    },
    phoneLength: 7,
  },
  {
    dialCode: '+597',
    isoCode: 'SR',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/sr.svg',
    nameEn: 'Suriname',
    nameAr: 'سورينام',
    emoji: '🇸🇷',
    secondFlag:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SR.svg',
    region: 'Americas',
    timezones: {
      'America/Paramaribo': '-03:00',
    },
    phoneLength: 7,
  },
  {
    dialCode: '+47',
    isoCode: 'SJ',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/sj.svg',
    nameEn: 'Svalbard and Jan Mayen',
    nameAr: 'سفالبارد وجان ماين',
    emoji: '🇸🇯',
    secondFlag:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SJ.svg',
    region: 'Europe',
    timezones: {
      'Arctic/Longyearbyen': '+02:00',
    },
    phoneLength: 8,
  },
  {
    dialCode: '+268',
    isoCode: 'SZ',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/sz.svg',
    nameEn: 'Eswatini',
    nameAr: 'إيسواتيني',
    emoji: '🇸🇿',
    secondFlag:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SZ.svg',
    region: 'Africa',
    timezones: {
      'Africa/Mbabane': '+02:00',
    },
    phoneLength: 8,
  },
  {
    dialCode: '+46',
    isoCode: 'SE',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/se.svg',
    nameEn: 'Sweden',
    nameAr: 'السويد',
    emoji: '🇸🇪',
    secondFlag:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SE.svg',
    region: 'Europe',
    timezones: {
      'Europe/Stockholm': '+02:00',
    },
    phoneLength: 7,
  },
  {
    dialCode: '+41',
    isoCode: 'CH',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/ch.svg',
    nameEn: 'Switzerland',
    nameAr: 'سويسرا',
    emoji: '🇨🇭',
    secondFlag:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CH.svg',
    region: 'Europe',
    timezones: {
      'Europe/Zurich': '+02:00',
    },
    phoneLength: 9,
  },
  {
    dialCode: '+963',
    isoCode: 'SY',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/sy.svg',
    nameEn: 'Syrian Arab Republic',
    nameAr: 'الجمهورية العربية السورية',
    emoji: '🇸🇾',
    secondFlag:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SY.svg',
    region: 'Asia',
    timezones: {
      'Asia/Damascus': '+03:00',
    },
    phoneLength: 7,
  },
  {
    dialCode: '+886',
    isoCode: 'TW',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/tw.svg',
    nameEn: 'Taiwan',
    nameAr: 'تايوان',
    emoji: '🇹🇼',
    secondFlag:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TW.svg',
    region: 'Asia',
    timezones: {
      'Asia/Taipei': '+08:00',
    },
    phoneLength: 9,
  },
  {
    dialCode: '+992',
    isoCode: 'TJ',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/tj.svg',
    nameEn: 'Tajikistan',
    nameAr: 'طاجيكستان',
    emoji: '🇹🇯',
    secondFlag:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TJ.svg',
    region: 'Asia',
    timezones: {
      'Asia/Dushanbe': '+05:00',
    },
    phoneLength: 9,
  },
  {
    dialCode: '+255',
    isoCode: 'TZ',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/tz.svg',
    nameEn: 'Tanzania, United Republic of Tanzania',
    nameAr: 'تنزانيا , جمهورية تنزانيا المتحدة',
    emoji: '🇹🇿',
    secondFlag:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TZ.svg',
    region: 'Africa',
    timezones: {
      'Africa/Dar_es_Salaam': '+03:00',
    },
    phoneLength: 7,
  },
  {
    dialCode: '+66',
    isoCode: 'TH',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/th.svg',
    nameEn: 'Thailand',
    nameAr: 'تايلاند',
    emoji: '🇹🇭',
    secondFlag:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TH.svg',
    region: 'Asia',
    timezones: {
      'Asia/Bangkok': '+07:00',
    },
    phoneLength: 9,
  },
  {
    dialCode: '+670',
    isoCode: 'TL',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/tl.svg',
    nameEn: 'Timor-Leste',
    nameAr: 'تيمور - ليشتي',
    emoji: '🇹🇱',
    secondFlag:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TL.svg',
    region: 'Asia',
    timezones: {
      'Asia/Dili': '+09:00',
    },
    phoneLength: 7,
  },
  {
    dialCode: '+228',
    isoCode: 'TG',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/tg.svg',
    nameEn: 'Togo',
    nameAr: 'توغو',
    emoji: '🇹🇬',
    secondFlag:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TG.svg',
    region: 'Africa',
    timezones: {
      'Africa/Lome': '+00:00',
    },
    phoneLength: 8,
  },
  {
    dialCode: '+690',
    isoCode: 'TK',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/tk.svg',
    nameEn: 'Tokelau',
    nameAr: 'توكيلاو',
    emoji: '🇹🇰',
    secondFlag:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TK.svg',
    region: 'Oceania',
    timezones: {
      'Pacific/Fakaofo': '+13:00',
    },
    phoneLength: 5,
  },
  {
    dialCode: '+676',
    isoCode: 'TO',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/to.svg',
    nameEn: 'Tonga',
    nameAr: 'تونغا',
    emoji: '🇹🇴',
    secondFlag:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TO.svg',
    region: 'Oceania',
    timezones: {
      'Pacific/Tongatapu': '+13:00',
    },
    phoneLength: 5,
  },
  {
    dialCode: '+1868',
    isoCode: 'TT',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/tt.svg',
    nameEn: 'Trinidad and Tobago',
    nameAr: 'ترينيداد وتوباغو',
    emoji: '🇹🇹',
    secondFlag:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TT.svg',
    region: 'Americas',
    timezones: {
      'America/Port_of_Spain': '-04:00',
    },
    phoneLength: 7,
  },
  {
    dialCode: '+216',
    isoCode: 'TN',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/tn.svg',
    nameEn: 'Tunisia',
    nameAr: 'تونس',
    emoji: '🇹🇳',
    secondFlag:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TN.svg',
    region: 'Africa',
    timezones: {
      'Africa/Tunis': '+01:00',
    },
    phoneLength: 8,
  },
  {
    dialCode: '+90',
    isoCode: 'TR',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/tr.svg',
    nameEn: 'Turkey',
    nameAr: 'تركيا',
    emoji: '🇹🇷',
    secondFlag:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TR.svg',
    region: 'Asia',
    timezones: {
      'Europe/Istanbul': '+03:00',
    },
    phoneLength: 11,
  },
  {
    dialCode: '+993',
    isoCode: 'TM',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/tm.svg',
    nameEn: 'Turkmenistan',
    nameAr: 'تركمانستان',
    emoji: '🇹🇲',
    secondFlag:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TM.svg',
    region: 'Asia',
    timezones: {
      'Asia/Ashgabat': '+05:00',
    },
    phoneLength: 8,
  },
  {
    dialCode: '+1649',
    isoCode: 'TC',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/tc.svg',
    nameEn: 'Turks and Caicos Islands',
    nameAr: 'جزر تركس وكايكوس',
    emoji: '🇹🇨',
    secondFlag:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TC.svg',
    region: 'Americas',
    timezones: {
      'America/Grand_Turk': '-04:00',
    },
    phoneLength: 10,
  },
  {
    dialCode: '+688',
    isoCode: 'TV',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/tv.svg',
    nameEn: 'Tuvalu',
    nameAr: 'توفالو',
    emoji: '🇹🇻',
    secondFlag:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TV.svg',
    region: 'Oceania',
    timezones: {
      'Pacific/Funafuti': '+12:00',
    },
    phoneLength: 5,
  },
  {
    dialCode: '+256',
    isoCode: 'UG',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/ug.svg',
    nameEn: 'Uganda',
    nameAr: 'أوغندا',
    emoji: '🇺🇬',
    secondFlag:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/UG.svg',
    region: 'Africa',
    timezones: {
      'Africa/Kampala': '+03:00',
    },
    phoneLength: 7,
  },
  {
    dialCode: '+380',
    isoCode: 'UA',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/ua.svg',
    nameEn: 'Ukraine',
    nameAr: 'أوكرانيا',
    emoji: '🇺🇦',
    secondFlag:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/UA.svg',
    region: 'Europe',
    timezones: {
      'Europe/Kyiv': '+03:00',
      'Europe/Simferopol': '+03:00',
    },
    phoneLength: 9,
  },
  {
    dialCode: '+971',
    isoCode: 'AE',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/ae.svg',
    nameEn: 'United Arab Emirates',
    nameAr: 'الإمارات العربية المتحدة',
    emoji: '🇦🇪',
    secondFlag:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AE.svg',
    region: 'Asia',
    timezones: {
      'Asia/Dubai': '+04:00',
    },
    phoneLength: 9,
  },
  {
    dialCode: '+44',
    isoCode: 'GB',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/gb.svg',
    nameEn: 'United Kingdom',
    nameAr: 'المملكة المتحدة',
    emoji: '🇬🇧',
    secondFlag:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GB.svg',
    region: 'Europe',
    timezones: {
      'Europe/London': '+01:00',
    },
    phoneLength: 10,
  },
  {
    dialCode: '+1',
    isoCode: 'US',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/us.svg',
    nameEn: 'United States',
    nameAr: 'الولايات المتحدة',
    emoji: '🇺🇸',
    secondFlag:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/US.svg',
    region: 'Americas',
    timezones: {
      'America/Adak': '-09:00',
      'America/Anchorage': '-08:00',
      'America/Boise': '-06:00',
      'America/Chicago': '-05:00',
      'America/Denver': '-06:00',
      'America/Detroit': '-04:00',
      'America/Indiana/Indianapolis': '-04:00',
      'America/Indiana/Knox': '-05:00',
      'America/Indiana/Marengo': '-04:00',
      'America/Indiana/Petersburg': '-04:00',
      'America/Indiana/Tell_City': '-05:00',
      'America/Indiana/Vevay': '-04:00',
      'America/Indiana/Vincennes': '-04:00',
      'America/Indiana/Winamac': '-04:00',
      'America/Juneau': '-08:00',
      'America/Kentucky/Louisville': '-04:00',
      'America/Kentucky/Monticello': '-04:00',
      'America/Los_Angeles': '-07:00',
      'America/Menominee': '-05:00',
      'America/Metlakatla': '-08:00',
      'America/New_York': '-04:00',
      'America/Nome': '-08:00',
      'America/North_Dakota/Beulah': '-05:00',
      'America/North_Dakota/Center': '-05:00',
      'America/North_Dakota/New_Salem': '-05:00',
      'America/Phoenix': '-07:00',
      'America/Sitka': '-08:00',
      'America/Yakutat': '-08:00',
      'Pacific/Honolulu': '-10:00',
    },
    phoneLength: 10,
  },
  {
    dialCode: '+598',
    isoCode: 'UY',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/uy.svg',
    nameEn: 'Uruguay',
    nameAr: 'أوروغواي',
    emoji: '🇺🇾',
    secondFlag:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/UY.svg',
    region: 'Americas',
    timezones: {
      'America/Montevideo': '-03:00',
    },
    phoneLength: 8,
  },
  {
    dialCode: '+998',
    isoCode: 'UZ',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/uz.svg',
    nameEn: 'Uzbekistan',
    nameAr: 'أوزبكستان',
    emoji: '🇺🇿',
    secondFlag:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/UZ.svg',
    region: 'Asia',
    timezones: {
      'Asia/Samarkand': '+05:00',
      'Asia/Tashkent': '+05:00',
    },
    phoneLength: 9,
  },
  {
    dialCode: '+678',
    isoCode: 'VU',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/vu.svg',
    nameEn: 'Vanuatu',
    nameAr: 'فانواتو',
    emoji: '🇻🇺',
    secondFlag:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/VU.svg',
    region: 'Oceania',
    timezones: {
      'Pacific/Efate': '+11:00',
    },
    phoneLength: 5,
  },
  {
    dialCode: '+58',
    isoCode: 'VE',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/ve.svg',
    nameEn: 'Venezuela, Bolivarian Republic of Venezuela',
    nameAr: 'فنزويلا , جمهورية فنزويلا البوليفارية',
    emoji: '🇻🇪',
    secondFlag:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/VE.svg',
    region: 'Americas',
    timezones: {
      'America/Caracas': '-04:00',
    },
    phoneLength: 7,
  },
  {
    dialCode: '+84',
    isoCode: 'VN',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/vn.svg',
    nameEn: 'Vietnam',
    nameAr: 'فيتنام',
    emoji: '🇻🇳',
    secondFlag:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/VN.svg',
    region: 'Asia',
    timezones: {
      'Asia/Ho_Chi_Minh': '+07:00',
    },
    phoneLength: 9,
  },
  {
    dialCode: '+1284',
    isoCode: 'VG',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/vg.svg',
    nameEn: 'Virgin Islands, British',
    nameAr: 'جزر فيرجن البريطانية',
    emoji: '🇻🇬',
    secondFlag:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/VG.svg',
    region: 'Americas',
    timezones: {
      'America/Tortola': '-04:00',
    },
    phoneLength: 7,
  },
  {
    dialCode: '+1340',
    isoCode: 'VI',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/vi.svg',
    nameEn: 'Virgin Islands, U.S.',
    nameAr: 'جزر فيرجن , الولايات المتحدة',
    emoji: '🇻🇮',
    secondFlag:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/VI.svg',
    region: 'Americas',
    timezones: {
      'America/St_Thomas': '-04:00',
    },
    phoneLength: 10,
  },
  {
    dialCode: '+681',
    isoCode: 'WF',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/wf.svg',
    nameEn: 'Wallis and Futuna',
    nameAr: 'واليس وفوتونا',
    emoji: '🇼🇫',
    secondFlag:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/WF.svg',
    region: 'Oceania',
    timezones: {
      'Pacific/Wallis': '+12:00',
    },
    phoneLength: 6,
  },
  {
    dialCode: '+967',
    isoCode: 'YE',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/ye.svg',
    nameEn: 'Yemen',
    nameAr: 'اليمن',
    emoji: '🇾🇪',
    secondFlag:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/YE.svg',
    region: 'Asia',
    timezones: {
      'Asia/Aden': '+03:00',
    },
    phoneLength: 9,
  },
  {
    dialCode: '+260',
    isoCode: 'ZM',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/zm.svg',
    nameEn: 'Zambia',
    nameAr: 'زامبيا',
    emoji: '🇿🇲',
    secondFlag:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/ZM.svg',
    region: 'Africa',
    timezones: {
      'Africa/Lusaka': '+02:00',
    },
    phoneLength: 9,
  },
  {
    dialCode: '+263',
    isoCode: 'ZW',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/zw.svg',
    nameEn: 'Zimbabwe',
    nameAr: 'زمبابوي',
    emoji: '🇿🇼',
    secondFlag:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/ZW.svg',
    region: 'Africa',
    timezones: {
      'Africa/Harare': '+02:00',
    },
    phoneLength: 9,
  },
  {
    dialCode: '+358',
    isoCode: 'AX',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/ax.svg',
    nameEn: 'Aland Islands',
    nameAr: 'جزر آلاند',
    emoji: '🇦🇽',
    secondFlag:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AX.svg',
    region: 'Europe',
    timezones: {
      'Europe/Mariehamn': '+03:00',
    },
    phoneLength: 11,
  },
  {
    dialCode: '+247',
    isoCode: 'AC',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/ac.svg',
    secondFlag: 'https://cdn.kcak11.com/CountryFlags/countries/ac.svg',
    nameEn: 'Ascension Island',
    region: 'Africa',
    nameAr: 'جزيرة أسنسيون',
    phoneLength: 11,
  },
  {
    dialCode: '+383',
    isoCode: 'XK',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/xk.svg',
    secondFlag: 'https://cdn.kcak11.com/CountryFlags/countries/xk.svg',
    nameEn: 'Kosovo',
    region: 'Europe',
    nameAr: 'كوسوفو',
    phoneLength: 8,
  },
  {
    dialCode: '+599',
    isoCode: 'AN',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/an.svg',
    secondFlag: 'https://cdn.kcak11.com/CountryFlags/countries/an.svg',
    nameEn: 'Netherlands Antilles',
    region: 'Europe',
    nameAr: 'جزر الأنتيل الهولندية',
    phoneLength: 9,
  },
  {
    nameEn: 'Bonaire, Sint Eustatius and Saba',
    nameAr: 'بوناير سنت ايستاس',
    region: 'Americas',
    timezones: {
      'America/Kralendijk': '-04:00',
    },
    isoCode: 'BQ',
    dialCode: '+599',
    emoji: '🇧🇶',
    secondFlag:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BQ.svg',
    phoneLength: 15,
    flag: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BQ.svg',
  },
  {
    nameEn: 'Curacao',
    nameAr: 'كوراكاو',
    region: 'Americas',
    timezones: {
      'America/Curacao': '-04:00',
    },
    isoCode: 'CW',
    dialCode: '+599',
    emoji: '🇨🇼',
    secondFlag:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CW.svg',
    phoneLength: 7,
    flag: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CW.svg',
  },
  {
    nameEn: 'Sint Maarten (Dutch part)',
    nameAr: 'سينت مارتن',
    region: 'Americas',
    timezones: {
      'America/Lower_Princes': '-04:00',
    },
    isoCode: 'SX',
    dialCode: '+599',
    emoji: '🇸🇽',
    secondFlag:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SX.svg',
    phoneLength: 10,
    flag: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SX.svg',
  },
  {
    dialCode: '+246',
    isoCode: 'IO',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/io.svg',
    nameEn: 'British Indian Ocean Territory',
    nameAr: 'إقليم المحيط الهندي البريطاني',
    region: 'Africa',
    timezones: {
      'Indian/Chagos': '+06:00',
    },
    emoji: '🇮🇴',
    secondFlag:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/IO.svg',
    phoneLength: 7,
  },
];
