import { NgFor } from '@angular/common';
import { Component } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-work-process',
  standalone: true,
  imports: [TranslateModule, NgFor],
  templateUrl: './work-process.component.html',
  styleUrl: './work-process.component.scss',
})
export class WorkProcessComponent {
  cards = [
    {
      icon: 'assets/svgs/plan.svg',
      title: 'Plan',
      steps: [
        `We use the information and guidance we receive at the launch meeting, along with our expertise, to begin laying out the project's foundational work. 
        We will help you define a clear strategy and develop a roadmap. By including you in our strategic development process, we ensure that our creative work is never based on ambiguous methods. 
        The plan we develop together will guide the entire project, ensuring the final product meets your goals.`,
      ],
    },
    {
      icon: 'assets/svgs/design.svg',
      title: 'Design',
      steps: [
        `This is where the excitement begins! Our design team will make a custom design for your business. 
        During the design phase, you’ll start seeing color palettes and design elements based on the solution map and wireframes. 
        We work collaboratively throughout the creative process, both internally and with you.`,
      ],
    },
    {
      icon: 'assets/svgs/implement.svg',
      title: 'Implement',
      steps: [
        'We then code the application along with creating the layout of the main sections and resolving the client’s feedback. ',
      ],
    },
    {
      icon: 'assets/svgs/test.svg',
      title: 'Test',
      steps: [
        `Before we launch your solution, our team completely tests all of its features, to ensure that the solution is optimized for good viewing. 
        We also re-test your solution after launch to ensure that everything is operational in the live environment.`,

      ],
    },
    {
      icon: 'assets/svgs/deploy.svg',
      title: 'Deploy',
      steps: [
        `Within the deployment process, we transform your solution from the development to the live environment. 
        Our team ensures smooth integration and deployment so that your customers can access and use your service effectively.`
      ],
    },
    {
      icon: 'assets/svgs/maintain.svg',
      title: 'Maintain',
      steps: [
        `Your solution is up and running! Now let us help support you and keep your solution going smoothly. 
        We pride ourselves on being able to fix issues quickly without any charges for the warranty period. After the warranty period, we offer maintenance packages at reduced rates.`
      ],
    },
  ];
}
