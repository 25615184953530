@if ( this.textControl.invalid && (this.textControl.dirty ||
this.textControl.touched)) { @if (this.textControl.errors?.['required']) {}
<div
  #container
  class="error-message-container"
  [style.right]="this.getValidationHintHorizontalPosition() + 'px'"
  [style.top]="this.getValidationVerticalPosition() + 'px'"
>
  <div
    [style.display]="this.showMessageValue ? 'block' : 'none'"
    class="error-message"
  >
    <div
      [style.right]="!this.isRtl() ? this.getPosition() + 'px' : ''"
      [style.left]="this.isRtl() ? this.getPosition() + 'px' : ''"
      class="upper-shape"
    >
      @if (this.textControl.errors?.['required']) {
      {{
        "validation.required"
          | translate : { value: (translationName | translate) }
      }}
      } @else if (this.textControl.errors?.['minlength']) {
      {{
              "validation.minLength"
                | translate
                  : { value: (translationName | translate), length: this.textControl.errors?.['minlength'].requiredLength }
      }}
      } @else if (this.textControl.errors?.['maxlength']) {
      {{
              "validation.maxlength"
                | translate
                  : { value: (translationName | translate), length: this.textControl.errors?.['maxlength'].requiredLength }
      }}
      } @else if (this.textControl.errors?.['email']) {
      {{ "validation.email" | translate }}
      } @else if (this.textControl.errors?.['requiresSpecialCharacters']) {
      {{ "passwordContains.specialCharacter" | translate }}

      } @else if (this.textControl.errors?.['requiresNumbers']) {
      {{ "passwordContains.number" | translate }}

      } @else if (this.textControl.errors?.['requiresLowercaseLetter']) {
      {{ "passwordContains.lowercase" | translate }}

      } @else if (this.textControl.errors?.['requiresUppercaseLetter']) {
      {{ "passwordContains.uppercase" | translate }}

      } @else if (this.textControl.errors?.['mismatch']) {
      {{ "login.form.match" | translate }}

      }
    </div>
  </div>
  <div
    (click)="this.openErrorMessage()"
    [style.right]="!this.isRtl() ? this.getPosition() + 'px' : ''"
    [style.left]="this.isRtl() ? this.getPosition() + 'px' : ''"
    class="error-icon"
  >
    <p>!</p>
  </div>
</div>
}
