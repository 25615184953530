<section id="services" class="services-container">
  <div class="intro">
    <div>
      <figure><img src="assets/svgs/tilted-logo.svg" alt="" /></figure>
      <h2>{{ "header.services" | translate }}</h2>
    </div>
    <h6>What we do?</h6>
  </div>
  <div class="services">
    <button
      (mouseover)="this.isLeftButtonBeingHovered = true"
      (mouseleave)="this.isLeftButtonBeingHovered = false"
      class="left"
      (click)="this.shiftLeft()"
    >
      <i
        ><img
          [src]="
            'assets/svgs/' +
            (this.isLeftButtonBeingHovered ? 'white-' : '') +
            'left-arrow.svg'
          "
          alt=""
      /></i>
    </button>
    @if (this.width > 1200) { @for (card of displayedCards; track $index; let
    first = $first; let last = $last) {
    <div class="card">
      <div>
        <i><img [src]="card.icon" [alt]="card.title" /></i>
      </div>
      <div>
        <h4>{{ card.title }}</h4>
      </div>
      <div>
        <h5>{{ card.description }}</h5>
      </div>
      @if (first) {
      <figure class="cards-left">
        <img src="assets/svgs/cards-left.svg" alt="" />
      </figure>
      } @if (last) {
      <figure class="cards-right">
        <img src="assets/svgs/cards-right.svg" alt="" />
      </figure>
      }
    </div>

    }} @else { @for (card of cards; track $index) {
    <div class="card">
      <div>
        <i><img [src]="card.icon" [alt]="card.title" /></i>
      </div>
      <div>
        <h4>{{ card.title }}</h4>
      </div>
      <div>
        <h5>{{ card.description }}</h5>
      </div>
    </div>
    } }
    <button
      (mouseover)="this.isRightButtonBeingHovered = true"
      (mouseleave)="this.isRightButtonBeingHovered = false"
      class="right"
      (click)="this.shiftRight()"
    >
      <i
        ><img
          [src]="
            'assets/svgs/' +
            (this.isRightButtonBeingHovered ? 'white-' : '') +
            'left-arrow.svg'
          "
          alt=""
      /></i>
    </button>
  </div>
  <figure class="services-top-right">
    <img src="assets/svgs/services-top-right.svg" alt="" />
  </figure>
  <figure class="services-bottom-left">
    <img src="assets/svgs/services-bottom-left.svg" alt="" />
  </figure>
</section>
