<div
  #controlContainer
  class="input-container"
  [style.margin-block-end]="this.config()['margin-block-end']"
  [style.border]="
    this.textControl.invalid &&
    (this.textControl.dirty || this.textControl.touched)
      ? '1px solid #e62929'
      : '1px solid #d2d2d2'
  "
>
  <hr
    class="row"
    [ngClass]="{
      open: this.isFocused || !!this.textControl.value
    }"
    [style.width]="
      (this.lang === 'en'
        ? this.config().placeholder.length / 3
        : this.config().placeholder.length / 4) +
      0.8 +
      'em'
    "
    [style.background-color]="
      this.textControl.invalid &&
      (this.textControl.dirty || this.textControl.touched)
        ? '#e62929'
        : '#d2d2d2'
    "
  />

  <label [for]="this.config().placeholder" class="input-label">
    <figure>
      <img
        [src]="
          'assets/svgs/form-controls/' +
          (this.isFocused ? '' : 'non-') +
          'active-' +
          this.config().icon +
          '.svg'
        "
        [alt]="this.config().icon + ' icon'"
      />
    </figure>
    <p
      [ngClass]="{
        lift: this.isFocused || !!this.textControl.value,
        'blue-text': this.isFocused,
      }"
    >
      {{ this.config().placeholder }}
    </p>
    @if (this.config().preventSpace) {
    <input
      appPreventSpace
      #input
      type="text"
      [id]="this.config().placeholder"
      [formControl]="this.textControl"
      [value]="this.textControl.value"
    />} @else {<input
      #input
      type="text"
      [id]="this.config().placeholder"
      [formControl]="this.textControl"
      [value]="this.textControl.value"
    />}
  </label>
</div>
<app-validation-hint
  [controlContainer]="controlContainer"
  [textControl]="this.textControl"
  [translationName]="this.config().placeholder"
></app-validation-hint>
