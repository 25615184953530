import { Injectable } from "@angular/core";
import { ApiService } from "../../core/services/api/api.service";
import { SendEmailBodyI } from "./interfaces/send-email.interface";

@Injectable({
    providedIn: 'root',
  })
  export class ContactUsApiService {
    constructor(private readonly apiService: ApiService) {}

    sendEmail(sendEmailBodyI: SendEmailBodyI){
        return this.apiService.post(
            `mail/send-email`,
            sendEmailBodyI
        )
    }
  }