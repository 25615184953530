import { DOCUMENT, NgFor, NgIf, isPlatformBrowser } from '@angular/common';
import {
  Component,
  HostListener,
  Inject,
  OnInit,
  PLATFORM_ID,
} from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-services',
  standalone: true,
  imports: [TranslateModule, NgFor, NgIf],
  templateUrl: './services.component.html',
  styleUrl: './services.component.scss',
})
export class ServicesComponent implements OnInit {
  currentCardIndex = 1;
  cards = [
    {
      icon: 'assets/svgs/web.svg',
      title: 'Web apps',
      description:
        'Make lively and easy-to-use web apps that fit your needs, giving smooth uses and strong tools.',
    },
    {
      icon: 'assets/svgs/mobile.svg',
      title: 'Mobile apps',
      description:
        'Lead the way in the mobile world with top-notch apps made to fit your needs, making sure they work smoothly and keep users happy.',
    },
    {
      icon: 'assets/svgs/ui-ux.svg',
      title: 'UI UX apps',
      description:
        'Lift your digital game with eye-catching interfaces and easy-to-use experiences, raising the bar for both looks and ease of use.',
    },
    {
      icon: 'assets/svgs/branding-and-logo-design.svg',
      title: 'Branding and logo design',
      description:
        `Shape your brand's face and leave a strong mark with smart logos and branding that speak to your audience.`,
    },
    {
      icon: 'assets/svgs/graphic-designs.svg',
      title: 'Graphic designs',
      description:
        `Turn your thoughts into bold visuals, from ads to digital pieces, grabbing attention and boosting your brand's look.`,
    },
    {
      icon: 'assets/svgs/devops.svg',
      title: 'DevOps',
      description:
        'Make your software better with our full DevOps services, helping teams work together better, make things faster, and get quality software out quicker.',
    },
  ];
  isLeftButtonBeingHovered = false;
  isRightButtonBeingHovered = false;
  displayedCards = [...this.cards].splice(0, 3);
  width: number = 1444;
  constructor(
    @Inject(DOCUMENT) private document: Document,
    @Inject(PLATFORM_ID) private readonly platformID: Object
  ) {}
  ngOnInit(): void {
    if (isPlatformBrowser(this.platformID))
      this.width = this.document.defaultView!.innerWidth;
  }

  shiftLeft() {
    if (this.currentCardIndex - 1 < 0) return;
    this.displayedCards = [...this.cards].splice(this.currentCardIndex - 1, 3);
    this.currentCardIndex--;
  }

  shiftRight() {
    if (this.currentCardIndex + 1 > 3) return;

    this.displayedCards = [...this.cards].splice(this.currentCardIndex + 1, 3);
    this.currentCardIndex++;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event & { target: { innerWidth: number } }) {
    const width = event.target?.innerWidth;

    this.width = width;
  }
}
