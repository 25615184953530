import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NumbersOnlyDirective } from '../../../directives/numbers-only/numbers-only.directive';
import { PreventSpaceDirective } from '../../../directives/prevent-space/prevent-space.directive';
import { COUNTRIES } from '../../../constants/countries.constant';
import { NgClass, NgFor } from '@angular/common';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { Country } from '../../../interfaces/country.interface';

@Component({
  selector: 'app-phone-number-input',
  standalone: true,
  imports: [
    PreventSpaceDirective,
    NumbersOnlyDirective,
    NgFor,
    ReactiveFormsModule,
    FormsModule,
    NgClass,
    TranslateModule,
  ],
  templateUrl: './phone-number-input.component.html',
  styleUrl: './phone-number-input.component.scss',
})
export class PhoneNumberInputComponent implements OnInit {
  COUNTRIES = COUNTRIES;
  selectedItem: Country = this.COUNTRIES.find(
    (country) => country.isoCode === 'JO'
  )!;
  isDropDownOpen = false;
  searchText = '';
  @Output() onCountrySelected = new EventEmitter<Country>();
  @Input({ required: true })
  phoneNumberControl!: FormControl<string | null>;

  ngOnInit(){
    this.onCountrySelected.emit(this.selectedItem)
  }

  openCountriesDropDown() {
    this.isDropDownOpen = !this.isDropDownOpen;
  }

  search(_value: any) {
    this.COUNTRIES = COUNTRIES.filter(
      (country) =>
        country.nameEn.toLowerCase().includes(this.searchText.toLowerCase()) ||
        country.nameAr.toLowerCase().includes(this.searchText.toLowerCase())
    );
  }

  selectItem(country: Country) {
    this.selectedItem = country;
    this.isDropDownOpen = false;
    this.onCountrySelected.emit(country);
  }
}
