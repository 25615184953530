import { NgIf } from '@angular/common';
import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Input,
  ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { StorageService } from '../../../../core/services/storage/storage.service';

@Component({
  selector: 'app-validation-hint',
  standalone: true,
  imports: [NgIf, TranslateModule],
  templateUrl: './validation-hint.component.html',
  styleUrl: './validation-hint.component.scss',
})
export class ValidationHintComponent implements AfterViewChecked {
  @Input({ required: true })
  textControl!: FormControl<any>;

  @Input({ required: true })
  translationName!: string;

  @Input({ required: true })
  controlContainer!: HTMLDivElement;

  @ViewChild('container')
  public container!: ElementRef<HTMLDivElement>;

  showMessage = false;

  firstMessageShown = false;

  position = 0;

  constructor(
    private readonly cd: ChangeDetectorRef,
    private readonly storageService: StorageService
  ) {}

  ngAfterViewChecked(): void {
    this.cd.detectChanges();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event & { target: { innerWidth: number } }) {
    return (
      parseInt(
        event.target?.innerWidth -
          this.container?.nativeElement?.getBoundingClientRect()?.right +
          ''
      ) - 10
    );
  }

  getPosition() {
    if (!this.container) return 0;
    this.position =
      parseInt(
        window.innerWidth -
          this.container.nativeElement.getBoundingClientRect().right +
          ''
      ) - 10;

    return this.position;
  }

  isRtl() {
    return this.storageService.language === 'ar';
  }

  getValidationHintHorizontalPosition() {
    if (!this.firstMessageShown) {
      this.firstMessageShown = true;
      this.showMessageValue = true;
      setTimeout(() => {
        this.showMessageValue = false;
      }, 2000);
    }
    return parseInt(
      window.innerWidth -
        this.controlContainer.getBoundingClientRect().right +
        ''
    );
  }

  getValidationVerticalPosition() {
    return parseInt(this.controlContainer.getBoundingClientRect().top + '') + 6;
  }

  get showMessageValue() {
    return this.showMessage;
  }

  set showMessageValue(showMessage) {
    this.showMessage = showMessage;
  }

  openErrorMessage() {
    this.showMessageValue = true;
    setTimeout(() => {
      this.showMessageValue = false;
    }, 2000);
  }
}
