<section id="clients" class="clients-container">
  <div class="intro">
    <div class="text">
      <figure><img src="assets/svgs/tilted-logo.svg" alt="" /></figure>
      <h2>{{ "header.clients" | translate }}</h2>
    </div>
    <h2>Projects done by <b>Code Mint</b></h2>
    <h2>company check our projects now</h2>
  </div>
  <div class="logos">
    @for (logo of logos; track $index) {
    <div class="logo">
      <figure><img [src]="logo.img" alt="" /></figure>
    </div>
    }
  </div>
</section>
