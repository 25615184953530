<section id="contact-us" class="contact-us-container">
  <div class="intro">
    <div>
      <h5>{{ "header.contactUs" | translate }} ----</h5>
      <p>
        You can ask us for anything contact with codemint and find your solution
      </p>
    </div>
    <hr />
    <div class="contact-info">
      <div class="contact">
        <div>
          <i><img src="assets/svgs/phone.svg" alt="" /></i>
        </div>
        <div>
          <h6>Phone Number</h6>
          <h6><a href="tel:+962799367271">+962 7 9936 7271</a></h6>
        </div>
      </div>
      <div class="contact">
        <div>
          <i><img src="assets/svgs/email.svg" alt="" /></i>
        </div>
        <div>
          <h6>Email</h6>
          <h6>
            <a href="mailto:info@code-mint.com">Info&#64;Code-Mint.com</a>
          </h6>
        </div>
      </div>
    </div>
    <figure class="contact-us-bottom-right">
      <img src="assets/svgs/contact-us-bottom-right.svg" alt="" />
    </figure>
  </div>
  <form class="form" [formGroup]="this.contactUsForm" (ngSubmit)="onSubmit()">
    <app-text-input
      [textControl]="this.contactUsForm.controls['email']"
      [config]="{
        placeholder: 'Email',
        minLength: 1,
        maxLength: 50,
        icon: 'email',
        preventSpace: true,
        'margin-block-end': '0.5em'
      }"
    ></app-text-input>
    <app-phone-number-input
      (onCountrySelected)="this.onCountrySelected($event)"
      [phoneNumberControl]="this.contactUsForm.controls['phoneNumber']"
    ></app-phone-number-input>
    <app-text-input
      [textControl]="this.contactUsForm.controls['fullName']"
      [config]="{
        placeholder: 'Full Name',
        minLength: 1,
        maxLength: 50,
        icon: 'name',
        preventSpace: true,
        'margin-block-end': '1em'
      }"
    ></app-text-input>
    <textarea
      [formControl]="this.contactUsForm.controls['message']"
      name=""
      id=""
      cols="30"
      rows="10"
      placeholder="Message"
    ></textarea>

    <div class="form-action">
      <div><button type="submit">Send</button></div>
    </div>
  </form>
</section>
