import { Component, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { ConnectivityService } from './core/services/connectivity/connectivity.service';
import { TranslationService } from './core/services/translate/translation.service';
import { HeaderComponent } from './pages/header/header.component';
import { FooterComponent } from './pages/footer/footer.component';
import { ClientsComponent } from './pages/clients/clients.component';
import { IntroComponent } from './pages/intro/intro.component';
import { WorkProcessComponent } from './pages/work-process/work-process.component';
import { ServicesComponent } from './pages/services/services.component';
import { ContactUsComponent } from './pages/contact-us/contact-us.component';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    HeaderComponent,
    FooterComponent,
    ClientsComponent,
    IntroComponent,
    WorkProcessComponent,
    ServicesComponent,
    ContactUsComponent,
  ],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent implements OnDestroy {
  public showOverlay = true;
  private readonly destroy$ = new Subject<void>();

  constructor(
    public readonly translationService: TranslationService,
    private readonly connectivityService: ConnectivityService
  ) {}

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
    this.connectivityService.stopChecking();
  }
}
