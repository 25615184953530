import { NgClass, NgIf } from '@angular/common';
import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  input,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { TranslationService } from '../../../../core/services/translate/translation.service';
import { PreventSpaceDirective } from '../../../directives/prevent-space/prevent-space.directive';
import { ValidationHintComponent } from '../validation-hint/validation-hint.component';
import { TextInputConfigI } from './interfaces/text-input-config.interface';

@Component({
  selector: 'app-text-input',
  standalone: true,
  imports: [
    NgClass,
    TranslateModule,
    NgIf,
    PreventSpaceDirective,
    ReactiveFormsModule,
    ValidationHintComponent,
  ],
  templateUrl: './text-input.component.html',
  styleUrl: './text-input.component.scss',
})
export class TextInputComponent implements OnInit, AfterViewChecked {
  @ViewChild('input') input!: ElementRef<HTMLInputElement>;

  config = input.required<TextInputConfigI>();
  isFocused = false;

  @Input({ required: true })
  textControl!: FormControl<string | null>;

  constructor(
    private readonly renderer: Renderer2,
    private readonly translationService: TranslationService,
    private readonly cd: ChangeDetectorRef
  ) {}

  ngAfterViewChecked(): void {
    this.cd.detectChanges();
  }

  lang = this.translationService.language();

  ngOnInit(): void {
    this.focusListener();
  }

  focusListener() {
    this.renderer.listen('window', 'click', (event: PointerEvent) => {
      if (!this.input) {
        this.isFocused = false;
        return;
      }
      if (event.target === this.input.nativeElement) this.isFocused = true;
      else this.isFocused = false;
    });
  }
}
