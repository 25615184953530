import { Component } from '@angular/core';
import {
  FormsModule,
  NonNullableFormBuilder,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { TextInputComponent } from '../../shared/components/form-controls/text-input/text-input.component';
import { PhoneNumberInputComponent } from '../../shared/components/form-controls/phone-number-input/phone-number-input.component';
import { ContactUsApiService } from './contact-us-api.service';
import { Subject, takeUntil } from 'rxjs';
import { ToastService } from '../../core/services/toast/toast.service';
import { Country } from '../../shared/interfaces/country.interface';

@Component({
  selector: 'app-contact-us',
  standalone: true,
  imports: [
    TranslateModule,
    ReactiveFormsModule,
    FormsModule,
    TextInputComponent,
    PhoneNumberInputComponent,
  ],
  templateUrl: './contact-us.component.html',
  styleUrl: './contact-us.component.scss',
})
export class ContactUsComponent {
  constructor(
    private readonly fb: NonNullableFormBuilder,
    private readonly toastService: ToastService,
    private readonly contactUsApiService: ContactUsApiService
  ) {}
  private readonly destroy$ = new Subject<void>();
  country?: Country

  contactUsForm = this.fb.group({
    fullName: [
      '',
      [Validators.required, Validators.min(3), Validators.max(320)],
    ],
    phoneNumber: ['', [Validators.required]],
    email: [
      '',
      [
        Validators.required,
        Validators.min(3),
        Validators.max(320),
        Validators.email,
      ],
    ],
    message: [
      '',
      [Validators.required, Validators.min(10), Validators.max(2200)],
    ],
  });

  onSubmit() {
    this.contactUsApiService
      .sendEmail({...this.contactUsForm.getRawValue(), phoneNumber: this.country?.dialCode + this.contactUsForm.controls['phoneNumber'].value})
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: () => {
          this.toastService.showSuccess(
            'Your request has been sent successfully!'
          );
          this.contactUsForm.reset()
        },
      });
  }

  onCountrySelected(country: Country) {
    this.country = country;
  }
}
